import { makeAutoObservable } from 'mobx';
import * as PIXI from 'pixi.js';
import { AppModel } from './AppModel';

import randomSvg from '../../assets/random.svg';
import clockSvg from '../../assets/time.svg';
import multiplySvg from '../../assets/cross.svg';

export enum TextureKey {
  Random = 'Random',
  Clock = 'Clock',
  Multiply = 'Multiply',
}

export const TextureMap: Record<TextureKey, string> = {
  [TextureKey.Random]: randomSvg,
  [TextureKey.Clock]: clockSvg,
  [TextureKey.Multiply]: multiplySvg,
};

export class PixiManagerModel {
  textures: Partial<Record<TextureKey, PIXI.Texture>> = {};

  constructor(private appModel: AppModel) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async load(): Promise<void> {
    Object.entries(TextureMap).map(([textureKey, textureLocation]) => {
      const location = textureLocation;

      if (!location) {
        return;
      }

      const texture = PIXI.Texture.from(location);

      this.textures[textureKey as TextureKey] = texture;
    });

    await Promise.all(
      Object.values(this.textures).map(
        (texture) => new Promise<void>((res) => texture.baseTexture.on('loaded', res)),
      ),
    );

    return;
  }
}
