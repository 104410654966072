import { Signal } from '../data/Signal';
import { TileType } from '../TileTypes';
import {
  TileDefinition,
  Side,
  TileSideIOType,
  ProcessFn,
  allLowSignal,
  TileGraphicsDefinition,
} from './TileDefinition';
import { colors } from '../../styles';
import { defaultTileGraphics } from './DefaultTileGraphics';

export class WireTile implements TileDefinition {
  type = TileType.Wire;
  name = 'Wire';

  createDefaultState = () => null;

  ioSides = {
    [Side.TOP]: TileSideIOType.INPUT_OUTPUT,
    [Side.RIGHT]: TileSideIOType.INPUT_OUTPUT,
    [Side.BOTTOM]: TileSideIOType.INPUT_OUTPUT,
    [Side.LEFT]: TileSideIOType.INPUT_OUTPUT,

    [Side.INSIDE]: TileSideIOType.NONE,
  };

  graphics = wireTileGraphics;

  process: ProcessFn = ({ instance: { meta } }) => {
    // if any input is HIGH, output HIGH on all sides
    if (Object.values(meta.inputs).some((signal) => signal === Signal.HIGH)) {
      return {
        ...allLowSignal,
        [Side.TOP]: Signal.HIGH,
        [Side.RIGHT]: Signal.HIGH,
        [Side.BOTTOM]: Signal.HIGH,
        [Side.LEFT]: Signal.HIGH,
      };
    } else {
      return allLowSignal;
    }
  };
}

export const wireTileGraphics: TileGraphicsDefinition = {
  type: TileType.Wire,
  draw: (graphics, { meta }) => {
    defaultTileGraphics(graphics);

    const isHigh = Object.values(meta.inputs).some((signal) => signal === Signal.HIGH);

    const noConnections = Object.values(meta.connections).every(
      (connected) => !connected,
    );

    graphics.beginFill(isHigh ? colors.signalColor : colors.noSignalColor);

    if (noConnections) {
      graphics.drawRect(30, 30, 60, 60);
    } else {
      graphics.drawRect(40, 40, 40, 40);

      if (meta.connections[Side.TOP]) {
        graphics.drawRect(40, 0, 40, 40);
      }

      if (meta.connections[Side.RIGHT]) {
        graphics.drawRect(80, 40, 40, 40);
      }

      if (meta.connections[Side.BOTTOM]) {
        graphics.drawRect(40, 80, 40, 40);
      }

      if (meta.connections[Side.LEFT]) {
        graphics.drawRect(0, 40, 40, 40);
      }
    }

    graphics.endFill();
  },
};
