import { makeAutoObservable } from 'mobx';

export class HistoryModel {
  previousActions: Action[] = [];
  futureActions: Action[] = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get canUndo() {
    return this.previousActions.length > 0;
  }

  get canRedo() {
    return this.futureActions.length > 0;
  }

  add(action: Action) {
    this.previousActions.push(action);
    this.futureActions = [];
  }

  undo() {
    if (this.canUndo) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const action = this.previousActions.pop()!;
      action.undo();
      this.futureActions.push(action);
    }
  }

  redo() {
    if (this.canRedo) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const action = this.futureActions.pop()!;
      action.do();
      this.previousActions.push(action);
    }
  }
}

export enum ActionType {
  AddTiles = 'AddTiles',
  RemoveTiles = 'RemoveTiles',
}

export interface Action {
  type: ActionType;
  description: string;
  do: () => void;
  undo: () => void;
}
