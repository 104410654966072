import { colors } from '../../styles';
import { Signal } from '../data/Signal';
import { TileType } from '../TileTypes';
import { defaultTileGraphics } from './DefaultTileGraphics';
import {
  TileDefinition,
  Side,
  TileSideIOType,
  ProcessFn,
  allHighSignal,
  allLowSignal,
  CreateDefaultStateFn,
  TileGraphicsDefinition,
  CreateActionsFn,
} from './TileDefinition';

export interface ButtonTileState {
  toggleState: Signal;
}

export class ButtonTile implements TileDefinition<ButtonTileState> {
  type = TileType.Button;

  name = 'Button';

  graphics = buttonTileGraphicsDefinition;

  createDefaultState: CreateDefaultStateFn<ButtonTileState> = () => ({
    toggleState: Signal.LOW,
  });

  createActions: CreateActionsFn<ButtonTileState> = ({
    instance: { state, globalTilePosition },
    eventBus,
  }) => ({
    toggle() {
      state.toggleState = state.toggleState === Signal.HIGH ? Signal.LOW : Signal.HIGH;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      eventBus!.trigger([globalTilePosition], 'Button Pressed');
    },
  });

  ioSides = {
    [Side.TOP]: TileSideIOType.OUTPUT,
    [Side.RIGHT]: TileSideIOType.OUTPUT,
    [Side.BOTTOM]: TileSideIOType.OUTPUT,
    [Side.LEFT]: TileSideIOType.OUTPUT,

    [Side.INSIDE]: TileSideIOType.NONE,
  };

  process: ProcessFn<ButtonTileState> = ({ instance: { state } }) => {
    if (state.toggleState === Signal.HIGH) {
      return { ...allHighSignal, [Side.INSIDE]: Signal.LOW };
    } else {
      return { ...allLowSignal, [Side.INSIDE]: Signal.LOW };
    }
  };
}

export const buttonTileGraphicsDefinition: TileGraphicsDefinition<ButtonTileState> = {
  type: TileType.Lever,
  draw: function (g, { state }): void {
    defaultTileGraphics(g);

    g.beginFill(
      state.toggleState === Signal.HIGH ? colors.signalColor : colors.noSignalColor,
    );

    g.lineStyle(3, 0x000000);
    g.drawRoundedRect(10, 10, 100, 100, 20);

    g.endFill();
  },
};
