import { makeAutoObservable } from 'mobx';
import { KeyMapModel } from './KeyMapModel';
import { AppModel } from './AppModel';

export class UserPreferencesModel {
  keyMapModel = new KeyMapModel();

  constructor(private appModel: AppModel) {
    makeAutoObservable(this, {}, { autoBind: true });
  }
}
