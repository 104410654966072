import { makeAutoObservable } from 'mobx';
import { CircuitModel, CircuitType } from './CircuitModel';
import { Action, HistoryModel } from './HistoryModel';
import { TileType } from './TileTypes';

const logicCircuitTiles = [
  TileType.And,
  TileType.Bridge,
  TileType.Button,
  TileType.Clock,
  TileType.Delay,
  TileType.Diode,
  TileType.Lever,
  TileType.Not,
  TileType.Random,
  TileType.Wire,
];

const structureCircuitTiles = [
  ...logicCircuitTiles,
  TileType.InsideChipPortal,
  TileType.OutsideChipPortal,
];

export const placeableTilesMap = {
  [CircuitType.Main]: logicCircuitTiles,
  [CircuitType.ChipLogic]: logicCircuitTiles,
  [CircuitType.ChipStructure]: structureCircuitTiles,
};

export class CircuitEditorModel {
  historyModel = new HistoryModel();

  constructor(public circuitModel: CircuitModel) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  runAction(action: Action) {
    action.do();
    this.historyModel.add(action);
  }

  get placeableTiles() {
    return placeableTilesMap[this.circuitModel.circuitType];
  }
}
