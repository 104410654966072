import { makeAutoObservable } from 'mobx';
import { CircuitModel } from './CircuitModel';
import { Point } from './Point';

export class StructureCircuitModel {
  originLocation: Point = new Point(0, 0);

  constructor(public circuitModel: CircuitModel) {
    makeAutoObservable(this, {}, { autoBind: true });
  }
}
