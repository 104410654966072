export interface PointData {
  x: number;
  y: number;
}

export type PointString = `${number},${number}`;

export function isPointString(data: string): data is PointString {
  return /^\d+,\d+$/.test(data);
}

export class Point implements PointData {
  /** Just an alias for fromData */
  static fromDto(data: PointData): Point {
    return Point.fromData(data);
  }

  static zero(): Point {
    return new Point(0, 0);
  }

  static fromData(data: PointData): Point {
    return new Point(data.x, data.y);
  }

  static fromTuple(data: [number, number]): Point {
    return new Point(data[0], data[1]);
  }

  static fromString(data: PointString): Point {
    const [x, y] = data.split(',').map(Number);
    return new Point(x, y);
  }

  constructor(public readonly x: number, public readonly y: number) {}

  get data(): PointData {
    return { x: this.x, y: this.y };
  }

  get asTuple(): [number, number] {
    return [this.x, this.y];
  }

  equals(point: Point): boolean {
    return this.x === point.x && this.y === point.y;
  }

  add(point: Point): Point {
    return new Point(this.x + point.x, this.y + point.y);
  }

  sub(point: Point): Point {
    return new Point(this.x - point.x, this.y - point.y);
  }

  mult(point: Point): Point {
    return new Point(this.x * point.x, this.y * point.y);
  }

  div(point: Point): Point {
    return new Point(this.x / point.x, this.y / point.y);
  }

  scale(factor: number): Point {
    return new Point(this.x * factor, this.y * factor);
  }

  distance(point: Point): number {
    return Math.sqrt(Math.pow(point.x - this.x, 2) + Math.pow(point.y - this.y, 2));
  }

  manhattanDistance(point: Point): number {
    return Math.abs(point.x - this.x) + Math.abs(point.y - this.y);
  }

  isNeighbor(point: Point): boolean {
    return this.manhattanDistance(point) === 1;
  }

  getNeighborDirection(point: Point): Point {
    return this.sub(point);
  }

  isDiagonalNeighbor(point: Point): boolean {
    return (
      this.manhattanDistance(point) === 2 && this.x !== point.x && this.y !== point.y
    );
  }

  isNeighborOrDiagonalNeighbor(point: Point): boolean {
    return this.isNeighbor(point) || this.isDiagonalNeighbor(point);
  }

  get left(): Point {
    return new Point(this.x - 1, this.y);
  }

  get right(): Point {
    return new Point(this.x + 1, this.y);
  }

  get up(): Point {
    return new Point(this.x, this.y - 1);
  }

  get down(): Point {
    return new Point(this.x, this.y + 1);
  }

  get neighbors(): Point[] {
    return [this.left, this.right, this.up, this.down];
  }

  clone(): Point {
    return new Point(this.x, this.y);
  }

  /** Can't be named toString due to MobX */
  asString(): PointString {
    return `${this.x},${this.y}`;
  }

  toDto(): PointData {
    return this.data;
  }
}
