import { Container } from '@pixi/react';
import { GridLines } from '../pixi/GridLines';
import { observer } from 'mobx-react-lite';
import { Point } from '../../models/Point';
import { GridViewModel } from './Grid';

export interface GridLineLayerProps {
  gridViewModel: GridViewModel;
}

export const GridLineLayer = observer((props: GridLineLayerProps) => {
  const { gridViewModel } = props;

  return (
    <Container>
      <GridLines
        height={gridViewModel.height}
        width={gridViewModel.width}
        color={[0.13, 0.13, 0.13]}
        offset={gridViewModel.offset}
        lineThickness={0.5 * gridViewModel.gridLineThicknessMultiplier}
        pitch={new Point(gridViewModel.tileSize, gridViewModel.tileSize)}
        resolution={gridViewModel.resolution}
      />

      <GridLines
        height={gridViewModel.height}
        width={gridViewModel.width}
        color={[0.13, 0.13, 0.13]}
        // color={[0.06, 0.06, 0.06]}
        offset={gridViewModel.offset}
        lineThickness={1.5 * gridViewModel.gridLineThicknessMultiplier}
        // lineThickness={2 * gridViewModel.gridLineThicknessMultiplier} // this looks nice, but can be obstructive
        pitch={
          new Point(
            gridViewModel.tileSize * gridViewModel.majorGridLineCount,
            gridViewModel.tileSize * gridViewModel.majorGridLineCount,
          )
        }
        resolution={gridViewModel.resolution}
      />
    </Container>
  );
});
