import { makeAutoObservable } from 'mobx';
import { ChipId } from '../types/ids';
import { ChipModel } from './ChipModel';
import { ChipEditorModel } from './ChipEditorModel';

export class ChipStoreModel {
  chipMap = new Map<ChipId, ChipModel>();
  chipEditorMap = new Map<ChipId, ChipEditorModel>();

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  addChip(chipModel: ChipModel) {
    this.chipMap.set(chipModel.id, chipModel);
    this.chipEditorMap.set(chipModel.id, new ChipEditorModel(chipModel));
  }

  getChip(chipId: ChipId) {
    return this.chipMap.get(chipId);
  }

  getChipEditor(chipId: ChipId) {
    return this.chipEditorMap.get(chipId);
  }

  deleteChip(chipId: ChipId) {
    this.chipMap.delete(chipId);
    this.chipEditorMap.delete(chipId);
  }

  get chips() {
    return Array.from(this.chipMap.values());
  }

  get chipEditors() {
    return Array.from(this.chipEditorMap.values());
  }
}
