import { makeAutoObservable } from 'mobx';
import { AppModel } from './AppModel';
import { ChipModel } from './ChipModel';
import { ProjectModel } from './ProjectModel';
import { SimulationModel } from './SimulationModel';
import { TileSelectModel } from './editor_modes/TileSelectModel';

export enum EditingMode {
  SELECT,
  PAN,
  PLACE_TILE,
  PLACE_CHIP,
  DELETE,
  LABEL,
}

export class EditorModel {
  simulationModel: SimulationModel;
  editingMode: EditingMode = EditingMode.SELECT;

  tileSelectModel = new TileSelectModel(this);

  currentChip: ChipModel;

  constructor(private appModel: AppModel, public project: ProjectModel) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.currentChip = project.mainChip;
    this.simulationModel = new SimulationModel(this, project.mainChip.logicCircuit);
    // this.circuitModel.tileManagerModel.add(
    //   new TileModel(this.appModel, TileType.Wire, new Point(0, 0)),
    // );
    // this.circuitModel.tileManagerModel.add(
    //   new TileModel(this.appModel, TileType.Wire, new Point(1, 0)),
    // );
    // this.circuitModel.tileManagerModel.add(
    //   new TileModel(this.appModel, TileType.Wire, new Point(2, 0)),
    // );
    // this.circuitModel.tileManagerModel.add(
    //   new TileModel(this.appModel, TileType.Wire, new Point(3, 0)),
    // );
    // this.circuitModel.tileManagerModel.add(
    //   new TileModel(this.appModel, TileType.Wire, new Point(4, 0)),
    // );
    // this.circuitModel.tileManagerModel.add(
    //   new TileModel(this.appModel, TileType.Wire, new Point(4, 1)),
    // );
    // this.circuitModel.tileManagerModel.add(
    //   new TileModel(this.appModel, TileType.Wire, new Point(4, 2)),
    // );

    // this.setup();
  }

  setEditingMode(editingMode: EditingMode) {
    this.editingMode = editingMode;
  }

  setCurrentChip(chip: ChipModel) {
    this.currentChip = chip;
  }

  get currentChipEditor() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.appModel.chipStoreModel.chipEditorMap.get(this.currentChip.id)!;
  }

  get currentCircuit() {
    return this.currentChipEditor.currentCircuitEditor.circuitModel;
  }

  // *setup() {
  //   const offset = 0;

  //   for (let x = 0 + offset; x < 1000 + offset; x++) {
  //     for (let y = 0 + offset; y < 1000 + offset; y++) {
  //       if (Math.random() < 0.15) {
  //         this.currentCircuitModel.tileManagerModel.add(
  //           new TileModel(this.appModel, TileType.Wire, new Point(x, y)),
  //         );
  //       }
  //     }
  //     yield new Promise((resolve) => setTimeout(resolve, 10));
  //   }
  // }
}
