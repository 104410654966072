import { GlobalTilePosition } from '../../types';
import { createTileId, TileId } from '../../types/ids';
import { deepClone } from '../../utils/clone';
import { Rotation } from '../data/Rotation';
import { EventBus } from '../EventBus';
import { TileManagerModel } from '../TileManagerModel';
import { TileType } from '../TileTypes';
import { Side, TileMeta, allLowSignal } from './TileDefinition';
import { tileDefinitionRegistry, TileRegistry } from './TileRegistry';

export interface TileInstanceDto {
  id: TileId;
  tileType: TileType;
  globalTileLocation: GlobalTilePosition;
  meta: TileMeta;
  state: unknown;
}

const allDisconnected: Record<Side, boolean> = {
  top: false,
  right: false,
  bottom: false,
  left: false,

  inside: false,
};

export const allConnected: Record<Side, boolean> = {
  top: true,
  right: true,
  bottom: true,
  left: true,

  inside: true,
};

export const setupDefaultMeta = (): TileMeta => ({
  inputs: { ...allLowSignal },
  outputs: { ...allLowSignal },
  rotation: Rotation.CW_0,
  connections: { ...allDisconnected },
});

export class TileInstance<TState = unknown> {
  id: TileId = createTileId();
  meta: TileMeta;
  state: TState;
  actions: Record<string, () => void> = {};

  constructor(
    public tileType: TileType,
    public globalTilePosition: GlobalTilePosition,
    eventBus: EventBus,
    public tileManagerModel: TileManagerModel,
  ) {
    this.meta = setupDefaultMeta();
    this.state = tileDefinitionRegistry.get(tileType).createDefaultState({
      meta: this.meta,
      eventBus,
    }) as TState;
    this.actions =
      tileDefinitionRegistry
        .get(tileType)
        .createActions?.({ instance: this, eventBus }) ?? {};
  }

  get location() {
    return this.globalTilePosition.location;
  }

  static fromDto(
    dto: TileInstanceDto,
    eventBus: EventBus,
    tileManagerModel: TileManagerModel,
  ) {
    const instance = new TileInstance(
      dto.tileType,
      dto.globalTileLocation,
      eventBus,
      tileManagerModel,
    );
    instance.meta = dto.meta;
    instance.state = dto.state;
    return instance;
  }

  toDto(): TileInstanceDto {
    return {
      id: this.id,
      meta: this.meta,
      state: this.state,
      tileType: this.tileType,
      globalTileLocation: this.globalTilePosition,
    };
  }

  clone(eventBus: EventBus) {
    const tile: TileInstance = new TileInstance(
      this.tileType,
      this.globalTilePosition,
      eventBus,
      this.tileManagerModel,
    );

    tile.meta = deepClone(this.meta);
    tile.state = deepClone(this.state);

    return tile;
  }
}
