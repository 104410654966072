import './App.css';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { AppModel, AppModelContext } from './models/AppModel';
import { EditorPage } from './routes/EditorPage';
import { ErrorPage } from './routes/ErrorPage';
import { StartPage } from './routes/StartPage';

// A hash router is used here such that the app can be deployed to GitHub pages
// depending on your deployment strategy, you may want to use a different router
const router = createBrowserRouter([
  {
    path: '/',
    element: <StartPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/editor',
    element: <EditorPage />,
  },
]);

export const appModel = new AppModel();

export function App() {
  return (
    <AppModelContext.Provider value={appModel}>
      <RouterProvider router={router} />
    </AppModelContext.Provider>
  );
}
