import { makeAutoObservable } from 'mobx';
import { CircuitModel } from './CircuitModel';
import { ChipInstanceId, TileId } from '../types/ids';
import { LogicGraph } from './LogicGraph';
import { ChipInstanceModel } from './ChipInstanceModel';
import { SimNode } from './SimulationModel';

export class LogicCircuitModel {
  logicGraph: Map<TileId, SimNode> = new Map();

  chipInstances = new Map<ChipInstanceId, ChipInstanceModel>();

  constructor(public circuitModel: CircuitModel) {
    makeAutoObservable(this, {}, { autoBind: true });
  }
}
