export class BiDirectionalMap<K, V> {
  private keyToValue: Map<K, V> = new Map();
  private valueToKey: Map<V, K> = new Map();

  constructor(keyEntries?: Iterable<[K, V]>) {
    if (keyEntries) {
      for (const [key, value] of keyEntries) {
        this.set(key, value);
      }
    }
  }

  // Set a key-value pair
  set(key: K, value: V): void {
    if (this.keyToValue.has(key)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const oldValue = this.keyToValue.get(key)!;
      this.valueToKey.delete(oldValue);
    }
    if (this.valueToKey.has(value)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const oldKey = this.valueToKey.get(value)!;
      this.keyToValue.delete(oldKey);
    }
    this.keyToValue.set(key, value);
    this.valueToKey.set(value, key);
  }

  // Get value by key
  getValue(key: K): V | undefined {
    return this.keyToValue.get(key);
  }

  // Get key by value
  getKey(value: V): K | undefined {
    return this.valueToKey.get(value);
  }

  // Check if key exists
  hasKey(key: K): boolean {
    return this.keyToValue.has(key);
  }

  // Check if value exists
  hasValue(value: V): boolean {
    return this.valueToKey.has(value);
  }

  // Delete by key
  deleteByKey(key: K): boolean {
    if (this.keyToValue.has(key)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const value = this.keyToValue.get(key)!;
      this.keyToValue.delete(key);
      this.valueToKey.delete(value);
      return true;
    }
    return false;
  }

  // Delete by value
  deleteByValue(value: V): boolean {
    if (this.valueToKey.has(value)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const key = this.valueToKey.get(value)!;
      this.keyToValue.delete(key);
      this.valueToKey.delete(value);
      return true;
    }
    return false;
  }

  // Clear the map
  clear(): void {
    this.keyToValue.clear();
    this.valueToKey.clear();
  }
}
