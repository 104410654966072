import { colors } from '../../styles';
import { lerp } from '../../utils/math';
import { Signal } from '../data/Signal';
import { TileType } from '../TileTypes';
import { defaultTileGraphics } from './DefaultTileGraphics';
import {
  TileDefinition,
  Side,
  TileSideIOType,
  allLowSignal,
  ProcessFn,
  TileGraphicsDefinition,
} from './TileDefinition';

interface DelayQueueItem {
  signal: Signal;
  timeStarted: number;
}

export interface DelayTileState {
  delayTimeMs: number;
  queue: DelayQueueItem[];
  lastInput: Signal | null;
}

export class DelayTile implements TileDefinition<DelayTileState> {
  type = TileType.Delay;
  name = 'Delay';
  graphics = delayTileGraphicsDefinition;

  createDefaultState = () => ({
    delayTimeMs: 10_000,
    queue: [],
    lastInput: null,
  });

  ioSides = {
    [Side.TOP]: TileSideIOType.NONE,
    [Side.RIGHT]: TileSideIOType.OUTPUT,
    [Side.BOTTOM]: TileSideIOType.NONE,
    [Side.LEFT]: TileSideIOType.INPUT,

    [Side.INSIDE]: TileSideIOType.NONE,
  };

  process: ProcessFn<DelayTileState> = ({
    instance: { meta, state, globalTilePosition },
    eventBus,
  }) => {
    // Handle input changes
    if (meta.inputs.left !== state.lastInput) {
      state.lastInput = meta.inputs.left;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      state.queue.push({ signal: state.lastInput!, timeStarted: Date.now() });
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.queue.shift()!;
        eventBus.trigger([globalTilePosition], 'Delay'); // Trigger the next simulation step
      }, state.delayTimeMs);
    }

    // return the most recent output
    if (state.queue.length > 0) {
      return { ...allLowSignal, right: state.queue[0].signal };
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return { ...allLowSignal, right: state.lastInput! };
    }
  };
}

export const delayTileGraphicsDefinition: TileGraphicsDefinition<DelayTileState> = {
  type: TileType.Lever,
  draw: function (g, { state }): void {
    defaultTileGraphics(g);

    g.beginFill(colors.noSignalColor);
    g.lineStyle(5);
    g.drawRect(15, 40, 90, 40);
    g.endFill();

    // TODO: Untested code
    let prevEnd = 0;
    for (const item of state.queue) {
      g.beginFill(
        item.signal === Signal.HIGH ? colors.signalColor : colors.noSignalColor,
      );

      const start = prevEnd;
      const end = Date.now() - item.timeStarted;

      const gStart = lerp(0, state.delayTimeMs, 15, 105, start);
      const gEnd = lerp(0, state.delayTimeMs, 15, 105, end);

      g.drawRect(gStart, 40, gEnd - 15, 40);

      g.endFill();

      prevEnd = end;
    }

    g.beginFill(0x000000);
    g.drawPolygon([40, 50, 80, 60, 40, 70]);
    g.endFill();
  },
};
