import { makeAutoObservable } from 'mobx';
import { CircuitModel, CircuitType } from './CircuitModel';
import { createChipId } from '../types/ids';
import { LogicCircuitModel } from './LogicCircuitModel';
import { StructureCircuitModel } from './StructureCircuitModel';

export class ChipModel {
  id = createChipId();

  isMain = false;
  logicCircuit = new LogicCircuitModel(new CircuitModel(CircuitType.ChipLogic));
  structureCircuit = new StructureCircuitModel(
    new CircuitModel(CircuitType.ChipStructure),
  );

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }
}
