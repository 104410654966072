import { ObservableMap } from 'mobx';
import { TileType } from '../TileTypes';
import { AndTile } from './AndTile';
import { BridgeTile } from './BridgeTile';
import { ButtonTile } from './ButtonTile';
import { ClockTile } from './ClockTile';
import { DelayTile } from './DelayTile';
import { DiodeTile } from './DiodeTile';
import { InsideChipPortalTile } from './InsideChipPortalTile';
import { LeverTile } from './LeverTile';
import { InverterTile } from './NotTile';
import { OutsideChipPortalTile } from './OutsideChipPortalTile';
import { RandomTile } from './RandomTile';
import { TileDefinition, TileMeta } from './TileDefinition';
import { allConnected, setupDefaultMeta } from './TileInstance';
import { WireTile } from './WireTile';
import * as PIXI from 'pixi.js';

export class TileRegistry {
  private registry = new Map<TileType, TileDefinition<unknown>>();

  register<TState = null>(tileDefinition: TileDefinition<TState>) {
    this.registry.set(tileDefinition.type, tileDefinition as TileDefinition<unknown>);
  }

  get(type: TileType): TileDefinition<unknown> {
    const tileDefinition = this.registry.get(type);

    if (!tileDefinition) {
      throw new Error(`Tile definition not found: ${type}`);
    }

    return tileDefinition;
  }
}

export const tileDefinitionRegistry = new TileRegistry();

tileDefinitionRegistry.register(new WireTile());
tileDefinitionRegistry.register(new DelayTile());
tileDefinitionRegistry.register(new RandomTile());
tileDefinitionRegistry.register(new DiodeTile());
tileDefinitionRegistry.register(new InverterTile());
tileDefinitionRegistry.register(new AndTile());
tileDefinitionRegistry.register(new BridgeTile());
tileDefinitionRegistry.register(new ClockTile());
tileDefinitionRegistry.register(new ButtonTile());
tileDefinitionRegistry.register(new InsideChipPortalTile());
tileDefinitionRegistry.register(new OutsideChipPortalTile());
tileDefinitionRegistry.register(new LeverTile());

export const imageMap = new ObservableMap<TileType, string>();

const texture = PIXI.RenderTexture.create({
  width: 120,
  height: 120,
});
const renderer = PIXI.autoDetectRenderer();

const addTileImage = (tileType: TileType, setCustomMeta?: (meta: TileMeta) => void) => {
  const g = new PIXI.Graphics();

  const tileDef = tileDefinitionRegistry.get(tileType);
  const meta = setupDefaultMeta();
  setCustomMeta?.(meta);
  const state = tileDefinitionRegistry.get(tileType).createDefaultState({
    meta,
  });

  tileDef.graphics.draw(g, { state, meta });

  renderer.render(g, { renderTexture: texture });

  // Create a sprite to extract pixels
  const sprite = new PIXI.Sprite(texture);
  renderer.render(sprite);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  imageMap.set(tileType, renderer.extract.canvas(sprite).toDataURL!());
};

export const generateTileImages = () => {
  addTileImage(TileType.And);
  addTileImage(TileType.Bridge);
  addTileImage(TileType.Button);
  addTileImage(TileType.Clock);
  addTileImage(TileType.Delay);
  addTileImage(TileType.Diode);
  addTileImage(TileType.InsideChipPortal);
  addTileImage(TileType.Lever);
  addTileImage(TileType.Not);
  addTileImage(TileType.OutsideChipPortal);
  addTileImage(TileType.Random);
  addTileImage(TileType.Wire, (meta) => {
    meta.connections = { ...allConnected };
  });
};
