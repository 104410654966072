import { Link } from 'react-router-dom';

import { Card } from '../components/base';
import { absolute, flexCenter, fullSize, padding } from '../styles';

export function StartPage() {
  return (
    <div css={[absolute(), fullSize, flexCenter]}>
      <Card
        css={[{ width: 'min(90%, 400px)', height: 'min(90%, 400px)' }, padding('xl')]}
      >
        <Link to="/editor">Go to Circuit Editor</Link>
      </Card>
    </div>
  );
}
