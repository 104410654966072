import { colors } from '../../styles';
import { Signal } from '../data/Signal';
import { TileType } from '../TileTypes';
import { Vector2 } from '../Vector2';
import { defaultTileGraphics } from './DefaultTileGraphics';
import {
  TileDefinition,
  Side,
  TileSideIOType,
  ProcessFn,
  allLowSignal,
  TileGraphicsDefinition,
} from './TileDefinition';

export class BridgeTile implements TileDefinition {
  type = TileType.Bridge;

  name = 'Bridge';

  createDefaultState = () => null;

  graphics = bridgeTileGraphicsDefinition;

  ioSides = {
    [Side.TOP]: TileSideIOType.INPUT_OUTPUT,
    [Side.RIGHT]: TileSideIOType.INPUT_OUTPUT,
    [Side.BOTTOM]: TileSideIOType.INPUT_OUTPUT,
    [Side.LEFT]: TileSideIOType.INPUT_OUTPUT,

    [Side.INSIDE]: TileSideIOType.NONE,
  };

  process: ProcessFn = ({ instance: { meta } }) => {
    // Pass signals through without introducing feedback loops
    return {
      ...allLowSignal,
      left: meta.inputs.right,
      right: meta.inputs.left,
      top: meta.inputs.bottom,
      bottom: meta.inputs.top,
    };
  };
}

export const bridgeTileGraphicsDefinition: TileGraphicsDefinition = {
  type: TileType.Lever,
  draw: function (g, { meta }): void {
    defaultTileGraphics(g);

    const isVerticalHigh = [meta.inputs.top, meta.inputs.bottom].some(
      (signal) => signal === Signal.HIGH,
    );

    const isHorizontalHigh = [meta.inputs.left, meta.inputs.right].some(
      (signal) => signal === Signal.HIGH,
    );

    const wireWidth = 30;
    const center = new Vector2(60, 60);
    const jumpHeight = 10; // Height of the horizontal jump
    const archWidth = 50; // Width of the jump arch

    // Set colors based on signal state
    g.beginFill(isVerticalHigh ? colors.signalColor : colors.noSignalColor);
    g.drawRect(center.x - wireWidth / 2, 0, wireWidth, 120);
    g.endFill();

    g.beginFill(isHorizontalHigh ? colors.signalColor : colors.noSignalColor);
    g.moveTo(0, center.y - wireWidth / 2);
    g.lineTo(center.x - archWidth / 2, center.y - wireWidth / 2);
    g.quadraticCurveTo(
      center.x,
      center.y - wireWidth / 2 - jumpHeight - archWidth / 2,
      center.x + archWidth / 2,
      center.y - wireWidth / 2,
    );
    g.lineTo(120, center.y - wireWidth / 2);
    g.lineTo(120, center.y + wireWidth / 2);
    g.lineTo(center.x + archWidth / 2, center.y + wireWidth / 2);
    g.quadraticCurveTo(
      center.x,
      center.y + wireWidth / 2 - jumpHeight - archWidth / 2,
      center.x - archWidth / 2,
      center.y + wireWidth / 2,
    );
    g.lineTo(0, center.y + wireWidth / 2);

    g.endFill();

    g.lineStyle(2, colors.wireBorder);

    g.moveTo(0, center.y - wireWidth / 2);
    g.lineTo(center.x - archWidth / 2, center.y - wireWidth / 2);
    g.quadraticCurveTo(
      center.x,
      center.y - wireWidth / 2 - jumpHeight - archWidth / 2,
      center.x + archWidth / 2,
      center.y - wireWidth / 2,
    );
    g.lineTo(120, center.y - wireWidth / 2);

    g.moveTo(120, center.y + wireWidth / 2);
    g.lineTo(center.x + archWidth / 2, center.y + wireWidth / 2);
    g.quadraticCurveTo(
      center.x,
      center.y + wireWidth / 2 - jumpHeight - archWidth / 2,
      center.x - archWidth / 2,
      center.y + wireWidth / 2,
    );
    g.lineTo(0, center.y + wireWidth / 2);
  },
};
