import { TileId } from '../../types/ids';
import { Signal } from '../data/Signal';
import { TileType } from '../TileTypes';
import { defaultTileGraphics } from './DefaultTileGraphics';
import {
  allHighSignal,
  allLowSignal,
  CreateDefaultStateFn,
  ProcessFn,
  Side,
  TileDefinition,
  TileGraphicsDefinition,
  TileSideIOType,
} from './TileDefinition';

export interface OutsideChipPortalTileState {
  label: string | null;
  hue: number;

  connectedInsideChipPortalTilePosition: TileId | null;
}

export class OutsideChipPortalTile implements TileDefinition<OutsideChipPortalTileState> {
  graphics = outsideChipPortalTileGraphicsDefinition;
  type = TileType.OutsideChipPortal;

  name = 'Portal Tile (Outside)';

  createDefaultState: CreateDefaultStateFn<OutsideChipPortalTileState> = () => ({
    label: null,
    hue: 0,
    connectedInsideChipPortalTilePosition: null,
  });

  ioSides = {
    [Side.TOP]: TileSideIOType.INPUT_OUTPUT,
    [Side.RIGHT]: TileSideIOType.INPUT_OUTPUT,
    [Side.BOTTOM]: TileSideIOType.INPUT_OUTPUT,
    [Side.LEFT]: TileSideIOType.INPUT_OUTPUT,

    [Side.INSIDE]: TileSideIOType.INPUT_OUTPUT,
  };

  process: ProcessFn<OutsideChipPortalTileState> = ({ instance: { meta } }) => {
    const inputValues = Object.values(meta.inputs);

    const isHigh = inputValues.some((signal) => signal === Signal.HIGH);

    if (isHigh) {
      return {
        ...allHighSignal,
      };
    } else {
      return {
        ...allLowSignal,
      };
    }
  };
}

export const outsideChipPortalTileGraphicsDefinition: TileGraphicsDefinition<OutsideChipPortalTileState> =
  {
    type: TileType.OutsideChipPortal,
    draw: function (g, { state, meta }): void {
      defaultTileGraphics(g);

      const isHigh = Object.values(meta.outputs).some((sig) => sig === Signal.HIGH);

      g.beginFill({
        h: state.hue,
        s: 100,
        v: isHigh ? 100 : 35,
      });
      g.drawRect(5, 5, 110, 110);
      g.endFill();
    },
  };
