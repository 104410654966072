import { Graphics } from '@pixi/react';
import * as PIXI from 'pixi.js';
import { observer } from 'mobx-react-lite';
import { BaseViewModel, useViewModelConstructor } from '../../utils/mobx/ViewModel';
import { makeSimpleAutoObservable } from '../../utils/mobx';
import { Point } from '../../models/Point';

const shaderCode = `
    precision mediump float;

    uniform float vpw;
    uniform float vph;
    uniform float thickness;

    uniform vec2 offset;
    uniform vec2 pitch;
    uniform vec4 color;

    void main() {
        float offX = -offset.x + gl_FragCoord.x;
        float offY = -offset.y + (vph - gl_FragCoord.y);
        
        float rX = min(abs(pitch.x - mod(offX, pitch.x)), abs(mod(offX, pitch.x)));
        float rY = min(abs(pitch.y - mod(offY, pitch.y)), abs(mod(offY, pitch.y)));

        if (int(rX) <= int(thickness) || int(rY) <= int(thickness)) {
            gl_FragColor = color;
        } else {
            gl_FragColor = vec4(0.0, 0.0, 0.0, 0.0);
        }
    }
`;

type GridLinesViewModelProps = GridLinesProps;

class GridLinesViewModel extends BaseViewModel<GridLinesViewModelProps> {
  constructor(props: GridLinesViewModelProps) {
    super(props);

    makeSimpleAutoObservable(this, { props: true }, { autoBind: true });
  }

  get width(): number {
    return this.props.width;
  }

  get height(): number {
    return this.props.height;
  }

  get lineThickness(): number {
    return this.props.lineThickness ?? 1;
  }

  get resolution(): number {
    return this.props.resolution ?? 2;
  }

  get color(): [number, number, number] {
    return this.props.color ?? [1, 1, 1];
  }

  get offset(): Point {
    return this.props.offset ?? Point.zero();
  }

  get pitch(): Point {
    return this.props.pitch ?? new Point(50, 50);
  }

  get thickness(): number {
    return this.lineThickness * this.resolution;
  }

  get uniformColor(): [number, number, number, number] {
    return [...this.color, 1.0];
  }

  get vpw(): number {
    return this.width * this.resolution;
  }

  get vph(): number {
    return this.height * this.resolution;
  }

  get uniformOffset(): [number, number] {
    return [this.offset.x * this.resolution, this.offset.y * this.resolution];
  }

  get uniformPitch(): [number, number] {
    return [this.pitch.x * this.resolution, this.pitch.y * this.resolution];
  }

  get uniforms() {
    return {
      thickness: this.thickness,
      color: this.uniformColor,
      vpw: this.vpw,
      vph: this.vph,
      offset: this.uniformOffset,
      pitch: this.uniformPitch,
    };
  }

  get drawFn() {
    const uniforms = this.uniforms;
    const width = this.width;
    const height = this.height;

    return (g: PIXI.Graphics) => {
      const gridShader = new PIXI.Filter(undefined, shaderCode, uniforms);

      g.clear();
      g.beginFill(0x000000);
      g.drawRect(0, 0, width, height);
      g.filters = [gridShader];
      g.endFill();
    };
  }
}

export interface GridLinesProps {
  width: number;
  height: number;
  color?: [number, number, number];
  offset?: Point;
  lineThickness?: number;
  pitch?: Point;
  resolution?: number;
}

export const GridLines = observer((props: GridLinesProps) => {
  const gridLinesViewModel = useViewModelConstructor(GridLinesViewModel, props);

  return <Graphics draw={gridLinesViewModel.drawFn} />;
});
