export function deepClone<T>(obj: T): T {
  if (obj === null || typeof obj !== 'object') {
    return obj; // Primitive values or null
  }

  if (Array.isArray(obj)) {
    return obj.map(deepClone) as unknown as T;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const clonedObj: Record<string, any> = {};
  for (const key of Object.keys(obj)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    clonedObj[key] = deepClone((obj as Record<string, any>)[key]);
  }

  return clonedObj as T;
}
