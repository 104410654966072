import { makeAutoObservable } from 'mobx';
import { Point } from './Point';
import { Bounds2D, withinBounds2DInclusive } from '../utils/bounds';
import { Direction } from '../utils/direction';
import { TileId } from '../types/ids';
import { TileInstance, TileInstanceDto } from './tiles/TileInstance';
import { EventBus } from './EventBus';

export const MIN_TILE_POSITION_VALUE = -16383;
export const MAX_TILE_POSITION_VALUE = 16384;

export const TILE_POSITION_VALUE_RANGE =
  MAX_TILE_POSITION_VALUE - MIN_TILE_POSITION_VALUE;

export function toIndex(a: number, b: number): number {
  const aMapped = a - MIN_TILE_POSITION_VALUE;
  const bMapped = b - MIN_TILE_POSITION_VALUE;

  return aMapped | (bMapped << 15);
}

export function fromIndex(c: number): [number, number] {
  const b = c >> 15;
  const a = c ^ (b << 15);

  return [a + MIN_TILE_POSITION_VALUE, b + MIN_TILE_POSITION_VALUE];
}

export interface TileManagerDto {
  tiles: TileInstanceDto[];
}

export class TileManagerModel {
  tileMap: Record<number, TileInstance> = {};
  tiles: TileInstance[] = [];

  static fromDto(tileManagerDto: TileManagerDto, event: EventBus) {
    const manager = new TileManagerModel(event);

    manager.addAll(
      tileManagerDto.tiles.map((tileDto) =>
        TileInstance.fromDto(tileDto, event, manager),
      ),
    );

    return manager;
  }

  static fromTiles(tiles: TileInstance[], eventBus: EventBus) {
    const manager = new TileManagerModel(eventBus);
    manager.addAll(tiles);
    return manager;
  }

  constructor(public eventBus: EventBus) {
    makeAutoObservable(
      this,
      {
        // tileMap: observable.ref,
        // tiles: observable.ref,
      },
      { autoBind: true },
    );
  }

  add(tile: TileInstance) {
    if (
      tile.location.x < MIN_TILE_POSITION_VALUE ||
      tile.location.x > MAX_TILE_POSITION_VALUE ||
      tile.location.y < MIN_TILE_POSITION_VALUE ||
      tile.location.y > MAX_TILE_POSITION_VALUE
    ) {
      // TODO: Render the boundaries so that this doesn't occur

      throw new Error('Tile position out of bounds');
    }

    const index = toIndex(tile.location.x, tile.location.y);

    this.tileMap[index] = tile;
    this.tiles.push(tile);
  }

  addAll(tiles: TileInstance[]) {
    for (const tile of tiles) {
      this.add(tile);
    }
  }

  remove(tile: TileInstance) {
    const index = toIndex(tile.location.x, tile.location.y);

    delete this.tileMap[index];
    const tileIndex = this.tiles.indexOf(tile);
    if (tileIndex !== -1) {
      this.tiles.splice(tileIndex, 1);
    }
  }

  get(position: Point): TileInstance | undefined {
    const index = toIndex(position.x, position.y);

    return this.tileMap[index];
  }

  getRaw(x: number, y: number): TileInstance | undefined {
    const index = toIndex(x, y);

    return this.tileMap[index];
  }

  has(position: Point): boolean {
    const index = toIndex(position.x, position.y);

    return this.tileMap[index] !== undefined;
  }

  /** Note this will just check the position and not the equality of the Tile */
  contains(tile: TileInstance): boolean {
    return this.has(tile.location);
  }

  inRange(bounds: Bounds2D): Map<TileId, TileInstance> {
    const boundsWidth = bounds.right - bounds.left;
    const boundsHeight = bounds.bottom - bounds.top;

    const boundsArea = boundsWidth * boundsHeight;

    if (boundsArea < this.tiles.length) {
      const tilesMap = new Map<TileId, TileInstance>();

      for (let x = bounds.left; x <= bounds.right; x++) {
        for (let y = bounds.top; y <= bounds.bottom; y++) {
          const tile = this.getRaw(x, y);

          if (tile) {
            tilesMap.set(tile.id, tile);
          }
        }
      }

      return tilesMap;
    } else {
      return new Map(
        this.tiles
          .filter((tile) => withinBounds2DInclusive(tile.location, bounds))
          .map((tile) => [tile.id, tile]),
      );
    }
  }

  getNeighbors(tile: TileInstance): TileInstance[] {
    const neighbors: TileInstance[] = [];
    const { x, y } = tile.location;
    const positions = [
      new Point(x - 1, y),
      new Point(x + 1, y),
      new Point(x, y - 1),
      new Point(x, y + 1),
    ];
    for (const position of positions) {
      const neighbor = this.get(position);
      if (neighbor) {
        neighbors.push(neighbor);
      }
    }
    return neighbors;
  }

  getNeighborsWithDirection(
    tile: TileInstance,
  ): Record<Direction, TileInstance | undefined> {
    return {
      [Direction.LEFT]: this.get(new Point(tile.location.x - 1, tile.location.y)),
      [Direction.RIGHT]: this.get(new Point(tile.location.x + 1, tile.location.y)),
      [Direction.UP]: this.get(new Point(tile.location.x, tile.location.y - 1)),
      [Direction.DOWN]: this.get(new Point(tile.location.x, tile.location.y + 1)),
    };
  }

  /** Function untested, not sure if it works
   * Will also need a new version of this which accounts for if the tiles are actually connected or not
   */
  getDisjointSets(): TileInstance[][] {
    const sets: TileInstance[][] = [];
    const visited = new Set<TileInstance>();
    for (const tile of this.tiles) {
      if (visited.has(tile)) {
        continue;
      }
      const set: TileInstance[] = [];
      const queue: TileInstance[] = [tile];
      while (queue.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const current = queue.shift()!;
        if (visited.has(current)) {
          continue;
        }
        visited.add(current);
        set.push(current);
        for (const neighbor of this.getNeighbors(current)) {
          queue.push(neighbor);
        }
      }
      sets.push(set);
    }
    return sets;
  }

  clone(cloneTiles = true) {
    const manager = TileManagerModel.fromTiles(
      cloneTiles ? this.tiles.map((tile) => tile.clone(this.eventBus)) : this.tiles,
      this.eventBus,
    );

    return manager;
  }

  toDto(): TileManagerDto {
    return {
      tiles: this.tiles.map((tile) => tile.toDto()),
    };
  }
}
