import { Container } from '@pixi/react';
import { useEffect, useRef } from 'react';
import { BaseViewModel, useViewModelConstructor } from '../../utils/mobx/ViewModel';
import { makeSimpleAutoObservable } from '../../utils/mobx';
import { observer } from 'mobx-react-lite';
import { observable, reaction } from 'mobx';
import { Bounds2D, bound2DInBounds, padBounds2D } from '../../utils/bounds';
import * as PIXI from 'pixi.js';
import { GridViewModel } from './Grid';
import { GridLineLayerProps } from './GridLineLayer';
import { AppModel, useAppModel } from '../../models/AppModel';
import { UserInteractionLayer } from './UserInteractionLayer';
import { TileId } from '../../types/ids';
import { TileInstance } from '../../models/tiles/TileInstance';
import { tileDefinitionRegistry } from '../../models/tiles/TileRegistry';

export interface TileLayerViewModelProps {
  gridViewModel: GridViewModel;
  appModel: AppModel;
  containerRef: React.RefObject<PIXI.Container<PIXI.DisplayObject>>;
}

export class TileLayerViewModel extends BaseViewModel<TileLayerViewModelProps> {
  constructor(props: TileLayerViewModelProps) {
    super(props);
    makeSimpleAutoObservable(
      this,
      {
        props: observable.shallow,
        renderedTilesMap: false,
        viewableTilesMap: observable.ref,
      },
      { autoBind: true },
    );
  }

  viewableTilesMap: Map<TileId, TileInstance> = new Map();
  renderedTilesMap = new Map<TileId, PIXI.Container>();

  recomputeViewableTiles(): Bounds2D {
    const { gridViewModel } = this.props;

    const paddedViewableWorldBounds = padBounds2D(
      gridViewModel.viewableWorldBounds,
      Math.floor(8 / gridViewModel.zoom), // dynamic padding - reduces the amount of recomputation and batches
      // 0,
    );

    const viewableTiles = gridViewModel.props.circuitModel.tileManagerModel.inRange(
      paddedViewableWorldBounds,
    );

    this.viewableTilesMap = viewableTiles;

    return paddedViewableWorldBounds;
  }

  setupRecomputationChecker() {
    const { gridViewModel } = this.props;

    let lastComputationBounds: Bounds2D | null = null;

    const viewableBoundsReactionDestroyer = reaction(
      () => gridViewModel.viewableWorldBounds,
      () => {
        if (!lastComputationBounds) {
          lastComputationBounds = this.recomputeViewableTiles();
          return;
        }

        if (bound2DInBounds(gridViewModel.viewableWorldBounds, lastComputationBounds)) {
          return;
        }

        lastComputationBounds = this.recomputeViewableTiles();
      },
      { fireImmediately: true },
    );

    const tilesReactionDestroyer = reaction(
      () => this.props.appModel.editorModel.currentCircuit.tiles.length,
      () => {
        lastComputationBounds = this.recomputeViewableTiles();
      },
      { fireImmediately: true },
    );

    return () => {
      viewableBoundsReactionDestroyer();
      tilesReactionDestroyer();
    };
  }

  setupContainerMover() {
    const destroyer = reaction(
      () =>
        [
          this.props.gridViewModel.offset,
          this.props.gridViewModel.zoom,
          this.props.containerRef,
        ] as const,
      ([offset, zoom, containerRef]) => {
        const container = containerRef.current;

        if (!container) {
          return;
        }

        if (container.scale) container.scale.set(zoom);

        if (container.position) container.position.set(offset.x, offset.y);
      },
      { fireImmediately: true },
    );

    return destroyer;
  }

  setupTileRenderer() {
    const destroyer = reaction(
      () => [this.viewableTilesMap, this.props.containerRef] as const,
      ([viewableTilesMap, containerRef]) => {
        const container = containerRef.current;

        if (!container) {
          return;
        }

        const tilesToRemove = Array.from(this.renderedTilesMap).filter(
          ([id]) => !viewableTilesMap.has(id),
        );

        for (const [id, renderedTile] of tilesToRemove) {
          container.removeChild(renderedTile);
          renderedTile.destroy(); // destroy might not be necessary since removeChild might do it
          this.renderedTilesMap.delete(id);
        }

        for (const [id, tileModel] of viewableTilesMap) {
          if (this.renderedTilesMap.has(id)) {
            continue;
          }

          const tileContainer = new PIXI.Container();

          const g = new PIXI.Graphics();

          tileDefinitionRegistry.get(tileModel.tileType).graphics.draw(g, {
            meta: tileModel.meta,
            state: tileModel.state,
          });

          tileContainer.addChild(g);

          tileContainer.position.set(
            tileModel.location.x * this.props.gridViewModel.unscaledTileSize,
            tileModel.location.y * this.props.gridViewModel.unscaledTileSize,
          );

          tileContainer.width = this.props.gridViewModel.unscaledTileSize;
          tileContainer.height = this.props.gridViewModel.unscaledTileSize;

          container.addChild(tileContainer);

          this.renderedTilesMap.set(id, tileContainer);
        }
      },
      {
        fireImmediately: true,
      },
    );

    return destroyer;
  }
}

export interface TileLayerProps {
  gridViewModel: GridViewModel;
}

export const TileLayer = observer((props: GridLineLayerProps) => {
  const { gridViewModel } = props;

  const appModel = useAppModel();
  const containerRef = useRef<PIXI.Container<PIXI.DisplayObject>>(null);
  const tileLayerViewModel = useViewModelConstructor(TileLayerViewModel, {
    gridViewModel,
    appModel,
    containerRef,
  });

  useEffect(() => {
    return tileLayerViewModel.setupContainerMover();
  }, [gridViewModel, containerRef]);
  useEffect(() => {
    return tileLayerViewModel.setupRecomputationChecker();
  }, [gridViewModel]);
  useEffect(() => {
    return tileLayerViewModel.setupTileRenderer();
  }, [gridViewModel, containerRef]);

  return (
    <Container ref={containerRef}>
      <UserInteractionLayer gridViewModel={gridViewModel} />
    </Container>
  );
});
