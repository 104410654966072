import { Point } from '../models/Point';
import { clamp } from './math';

export interface Bounds {
  min: number;
  max: number;
}

export interface Bounds2D {
  left: number;
  right: number;
  top: number;
  bottom: number;
}

export function padBounds(bounds: Bounds, padding: number): Bounds {
  return {
    min: bounds.min - padding,
    max: bounds.max + padding,
  };
}

export function padBounds2D(bounds: Bounds2D, padding: number): Bounds2D {
  return {
    left: bounds.left - padding,
    right: bounds.right + padding,
    top: bounds.top - padding,
    bottom: bounds.bottom + padding,
  };
}

export function boundInBounds(a: Bounds, b: Bounds): boolean {
  return a.min >= b.min && a.max <= b.max;
}

export function bound2DInBounds(a: Bounds2D, b: Bounds2D): boolean {
  return a.left >= b.left && a.right <= b.right && a.top >= b.top && a.bottom <= b.bottom;
}

export function withinBoundsInclusive(value: number, bounds: Bounds): boolean {
  return value >= bounds.min && value <= bounds.max;
}

export function withinBoundsExclusive(value: number, bounds: Bounds): boolean {
  return value > bounds.min && value < bounds.max;
}

export function withinBounds2DInclusive(point: Point, bounds: Bounds2D): boolean {
  return (
    withinBoundsInclusive(point.x, { min: bounds.left, max: bounds.right }) &&
    withinBoundsInclusive(point.y, { min: bounds.top, max: bounds.bottom })
  );
}

export function withinBounds2DExclusive(point: Point, bounds: Bounds2D): boolean {
  return (
    withinBoundsExclusive(point.x, { min: bounds.left, max: bounds.right }) &&
    withinBoundsExclusive(point.y, { min: bounds.top, max: bounds.bottom })
  );
}

export function clampToBounds(value: number, bounds: Bounds): number {
  return clamp(value, bounds.min, bounds.max);
}

export function clampToBounds2D(point: Point, bounds: Bounds2D): Point {
  return new Point(
    clampToBounds(point.x, { min: bounds.left, max: bounds.right }),
    clampToBounds(point.y, { min: bounds.top, max: bounds.bottom }),
  );
}
