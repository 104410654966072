import { makeAutoObservable } from 'mobx';
import React from 'react';

import { AppModel, AppModelContext } from './AppModel';

export enum Theme {
  LIGHT = 'light',
  DARK = 'dark',
}

export class UiModel {
  theme: Theme = Theme.DARK;

  constructor(private appModel: AppModel) {
    makeAutoObservable(this, {}, { autoBind: true });
  }
}

export const useUiModel = (): UiModel => {
  const appModel = React.useContext(AppModelContext);

  if (!appModel) {
    throw new Error('useAppModel must be used within AppModelProvider');
  }

  return appModel.uiModel;
};
