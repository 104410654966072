import { Signal } from '../data/Signal';
import { TileType } from '../TileTypes';
import {
  TileDefinition,
  Side,
  TileSideIOType,
  allLowSignal,
  ProcessFn,
  TileGraphicsDefinition,
} from './TileDefinition';
import { colors } from '../../styles';
import { appModel } from '../../App';
import { TextureKey } from '../PixiManagerModel';
import * as PIXI from 'pixi.js';
import {
  defaultTileGraphics,
  sideGraphics,
  connectionGraphics,
} from './DefaultTileGraphics';

export const andIoSides = {
  [Side.TOP]: TileSideIOType.INPUT,
  [Side.RIGHT]: TileSideIOType.OUTPUT,
  [Side.BOTTOM]: TileSideIOType.INPUT,
  [Side.LEFT]: TileSideIOType.NONE,

  [Side.INSIDE]: TileSideIOType.NONE,
};

export class AndTile implements TileDefinition {
  type = TileType.And;

  name = 'And';

  createDefaultState = () => null;

  graphics = andTileGraphics;

  ioSides = andIoSides;

  process: ProcessFn = ({ instance: { meta } }) => {
    // Output HIGH only if both top and bottom inputs are HIGH
    const top = meta.inputs.top ?? Signal.LOW;
    const bottom = meta.inputs.bottom ?? Signal.LOW;
    return {
      ...allLowSignal,
      right: top === Signal.HIGH && bottom === Signal.HIGH ? Signal.HIGH : Signal.LOW,
    };
  };
}

export const andTileGraphics: TileGraphicsDefinition = {
  type: TileType.And,
  draw: function (g, { meta }): void {
    defaultTileGraphics(g);
    sideGraphics(g, andIoSides, colors.signalColor, colors.noSignalColor, meta);
    connectionGraphics(g, colors.signalColor, colors.noSignalColor, meta);

    const color =
      meta.outputs.right === Signal.HIGH ? colors.signalColor : colors.noSignalColor;

    const sprite = new PIXI.Sprite(
      appModel.pixiManagerModel.textures[TextureKey.Multiply],
    );
    sprite.width = 70;
    sprite.height = 70;

    sprite.x = 25;
    sprite.y = 25;

    sprite.tint = color;

    g.addChild(sprite);
  },
};
