import { colors } from '../../styles';
import { Signal } from '../data/Signal';
import { TileType } from '../TileTypes';
import { defaultTileGraphics } from './DefaultTileGraphics';
import {
  TileDefinition,
  Side,
  TileSideIOType,
  allLowSignal,
  ProcessFn,
  TileGraphicsDefinition,
} from './TileDefinition';

export class DiodeTile implements TileDefinition {
  type = TileType.Diode;
  graphics = diodeTileGraphicsDefinition;

  name = 'Diode';

  createDefaultState = () => null;

  ioSides = {
    [Side.TOP]: TileSideIOType.NONE,
    [Side.RIGHT]: TileSideIOType.OUTPUT,
    [Side.BOTTOM]: TileSideIOType.NONE,
    [Side.LEFT]: TileSideIOType.INPUT,

    [Side.INSIDE]: TileSideIOType.NONE,
  };

  process: ProcessFn = ({ instance: { meta } }) => {
    // Pass the signal from left input to right output
    return { ...allLowSignal, right: meta.inputs.left ?? Signal.LOW };
  };
}

export const diodeTileGraphicsDefinition: TileGraphicsDefinition = {
  type: TileType.Diode,
  draw: function (g, { meta }): void {
    defaultTileGraphics(g);

    g.beginFill(
      meta.outputs.right === Signal.HIGH ? colors.signalColor : colors.noSignalColor,
    );

    g.drawPolygon([17.5, 10, 102.5, 60, 17.5, 110]);

    g.endFill();
  },
};
