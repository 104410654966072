import { border, colors, fullHeight } from '../../styles';
import { Flex, FlexColumn, FlexRow } from '../base/Flex';
import { useAppModel } from '../../models/AppModel';
import { EditingMode } from '../../models/EditorModel';
import { observer } from 'mobx-react-lite';
import { ModeButton, ModeButtonInfo } from './ModeButton';
import { TileButton } from './TileButton';

const modeButtons: ModeButtonInfo[] = [
  { name: 'Select', mode: EditingMode.SELECT, icon: 'select' },
  { name: 'Pan', mode: EditingMode.PAN, icon: 'move' },
  { name: 'Tile', mode: EditingMode.PLACE_TILE, icon: 'layer' },
  { name: 'Chips', mode: EditingMode.PLACE_CHIP, icon: 'layers' },
  { name: 'Labeler', mode: EditingMode.LABEL, icon: 'label' },
];

export interface SideBarProps {
  className?: string;
}

export const SideBar = observer((props: SideBarProps) => {
  const { className } = props;

  const appModel = useAppModel();

  const { editorModel: editingModel } = appModel;

  return (
    <>
      <FlexRow css={[fullHeight]}>
        <FlexColumn
          className={className}
          gap={10}
          css={[
            fullHeight,
            border('#00000042', 2),
            { borderTop: 'none' },
            { padding: 10, backgroundColor: colors.componentSecondary },
          ]}
        >
          {modeButtons.map(({ name, mode, icon }) => (
            <ModeButton
              key={mode}
              name={name}
              mode={mode}
              icon={icon}
              editorModel={editingModel}
            />
          ))}
        </FlexColumn>
      </FlexRow>
      {
        <TileSelector
          css={[
            {
              display:
                editingModel.editingMode === EditingMode.PLACE_TILE ? undefined : 'none',
            },
          ]}
        />
      }
    </>
  );
});

export interface TileSelectorProps {
  className?: string;
}

export const TileSelector = observer((props: TileSelectorProps) => {
  const { className } = props;
  const appModel = useAppModel();
  const { editorModel } = appModel;
  const { tileSelectModel } = editorModel;

  return (
    <div
      className={className}
      css={[
        border('#00000042', 2),
        {
          display: 'grid',
          // 2 columns,
          gridTemplateColumns: 'repeat(2, 1fr)',
          width: 140,
          gap: 10,
          height: 'fit-content',
          padding: 10,
          position: 'absolute',
          zIndex: 1,
          backgroundColor: colors.componentSecondary,
          left: 64,
        },
      ]}
    >
      {tileSelectModel.selectableTileTypes.map((tileType) => (
        <Flex justifyContent="center" alignItems="center" key={tileType}>
          <TileButton
            selected={tileSelectModel.selectedTileType === tileType}
            onClick={() => tileSelectModel.selectTileType(tileType)}
            tileType={tileType}
          />
        </Flex>
      ))}
    </div>
  );
});
