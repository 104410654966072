import {
  Direction,
  directionRotate180,
  directionRotateCCW,
  directionRotateCW,
} from '../../utils/direction';

export enum Rotation {
  CW_0,
  CW_90,
  CW_180,
  CW_270,
}

export const rotateCW = (rotation: Rotation): Rotation => {
  switch (rotation) {
    case Rotation.CW_0:
      return Rotation.CW_90;
    case Rotation.CW_90:
      return Rotation.CW_180;
    case Rotation.CW_180:
      return Rotation.CW_270;
    case Rotation.CW_270:
      return Rotation.CW_0;
  }
};

export const rotateCCW = (rotation: Rotation): Rotation => {
  switch (rotation) {
    case Rotation.CW_0:
      return Rotation.CW_270;
    case Rotation.CW_90:
      return Rotation.CW_0;
    case Rotation.CW_180:
      return Rotation.CW_90;
    case Rotation.CW_270:
      return Rotation.CW_180;
  }
};

export const rotate180 = (rotation: Rotation): Rotation => {
  switch (rotation) {
    case Rotation.CW_0:
      return Rotation.CW_180;
    case Rotation.CW_90:
      return Rotation.CW_270;
    case Rotation.CW_180:
      return Rotation.CW_0;
    case Rotation.CW_270:
      return Rotation.CW_90;
  }
};

export const rotateDirection = (direction: Direction, rotation: Rotation): Direction => {
  switch (rotation) {
    case Rotation.CW_0:
      return direction;
    case Rotation.CW_90:
      return directionRotateCW(direction);
    case Rotation.CW_180:
      return directionRotate180(direction);
    case Rotation.CW_270:
      return directionRotateCCW(direction);
  }
};
