import { GlobalTilePosition } from '../types';

export type EventHandler = (positions: GlobalTilePosition[], reason?: string) => void;

export class EventBus {
  private handlers: EventHandler[] = [];

  subscribe(handler: EventHandler) {
    this.handlers.push(handler);
  }

  trigger(positions: GlobalTilePosition[], reason?: string) {
    this.handlers.forEach((handler) => handler(positions, reason));
  }
}

export const globalEventBus = new EventBus();

// ! I think this event bus will be injected into the TileMeta | actually maybe not since it isn't something
// ! that should be saved
