export enum Signal {
  HIGH = 1,
  LOW = 0,
}

export const invertSignal = (signal: Signal): Signal =>
  signal === Signal.HIGH ? Signal.LOW : Signal.HIGH;

export const signalToBoolean = (signal: Signal): boolean => signal === Signal.HIGH;

export const booleanToSignal = (bool: boolean): Signal =>
  bool ? Signal.HIGH : Signal.LOW;

export const signalToString = (signal: Signal): string =>
  signal === Signal.HIGH ? 'HIGH' : 'LOW';
