import { appModel } from '../../App';
import { colors } from '../../styles';
import { Signal } from '../data/Signal';
import { TextureKey } from '../PixiManagerModel';
import { TileType } from '../TileTypes';
import {
  defaultTileGraphics,
  sideGraphics,
  connectionGraphics,
} from './DefaultTileGraphics';
import {
  TileDefinition,
  Side,
  TileSideIOType,
  ProcessFn,
  allLowSignal,
  TileGraphicsDefinition,
} from './TileDefinition';

import * as PIXI from 'pixi.js';

export const clockIoSides = {
  [Side.TOP]: TileSideIOType.NONE,
  [Side.RIGHT]: TileSideIOType.OUTPUT,
  [Side.BOTTOM]: TileSideIOType.NONE,
  [Side.LEFT]: TileSideIOType.INPUT,

  [Side.INSIDE]: TileSideIOType.NONE,
};

// export const clockIoSides = {
//   [Side.TOP]: TileSideIOType.INPUT_OUTPUT,
//   [Side.RIGHT]: TileSideIOType.INPUT_OUTPUT,
//   [Side.BOTTOM]: TileSideIOType.INPUT_OUTPUT,
//   [Side.LEFT]: TileSideIOType.INPUT_OUTPUT,

//   [Side.INSIDE]: TileSideIOType.NONE,
// };

export interface ClockTileState {
  interval: number | null;
  state: Signal;
}

export class ClockTile implements TileDefinition<ClockTileState> {
  type = TileType.Clock;

  name = 'Clock';

  graphics = clockTileGraphicsDefinition;

  createDefaultState = () => ({
    interval: null,
    state: Signal.LOW,
  });

  readonly ioSides = clockIoSides;

  process: ProcessFn<ClockTileState> = ({
    instance: { meta, state, globalTilePosition },
    eventBus,
  }) => {
    if (meta.inputs.left === Signal.HIGH) {
      if (!state.interval) {
        state.interval = setInterval(() => {
          state.state = state.state === Signal.HIGH ? Signal.LOW : Signal.HIGH;
          eventBus.trigger([globalTilePosition], 'Clock Cycle'); // Trigger the event system when toggled
        }, 1000);
      }

      return { ...allLowSignal, right: state.state };
    } else {
      if (state.interval) {
        clearInterval(state.interval);
      }

      return allLowSignal;
    }
  };
}

export const clockTileGraphicsDefinition: TileGraphicsDefinition<ClockTileState> = {
  type: TileType.Clock,
  draw: function (g, { meta }): void {
    defaultTileGraphics(g);
    sideGraphics(g, clockIoSides, colors.signalColor, colors.noSignalColor, meta);
    connectionGraphics(g, colors.signalColor, colors.noSignalColor, meta);

    const color =
      meta.outputs.right === Signal.HIGH ? colors.signalColor : colors.noSignalColor;

    const sprite = new PIXI.Sprite(appModel.pixiManagerModel.textures[TextureKey.Clock]);
    sprite.width = 70;
    sprite.height = 70;

    sprite.x = 25;
    sprite.y = 25;

    sprite.tint = color;

    g.addChild(sprite);
  },
};
