import { Stage as PixiStage } from '@pixi/react';
import { AppModelContext } from '../../models/AppModel';
import { ComponentProps } from 'react';

const ContextBridge = ({
  children,
  Context,
  render,
}: {
  children: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Context: React.Context<any>;
  render: (children: React.ReactNode) => React.ReactNode;
}) => {
  return (
    <Context.Consumer>
      {(value) => render(<Context.Provider value={value}>{children}</Context.Provider>)}
    </Context.Consumer>
  );
};

export const Stage = ({ children, ...props }: ComponentProps<typeof PixiStage>) => {
  return (
    <ContextBridge
      Context={AppModelContext}
      render={(children) => <PixiStage {...props}>{children}</PixiStage>}
    >
      {children}
    </ContextBridge>
  );
};
