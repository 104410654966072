export enum TileType {
  Wire = 'Wire',
  Not = 'Not',
  Diode = 'Diode',
  Delay = 'Delay',
  Lever = 'Lever',
  Button = 'Button',
  Random = 'Random',
  And = 'And',
  Bridge = 'Bridge',
  Clock = 'Clock',
  InsideChipPortal = 'InsideChipPortal',
  OutsideChipPortal = 'OutsideChipPortal',
}
