import { colors } from '../../styles';
import { Signal } from '../data/Signal';
import { TileType } from '../TileTypes';
import { defaultTileGraphics } from './DefaultTileGraphics';
import {
  allHighSignal,
  allLowSignal,
  CreateActionsFn,
  CreateDefaultStateFn,
  ProcessFn,
  Side,
  TileDefinition,
  TileGraphicsDefinition,
  TileSideIOType,
} from './TileDefinition';

export interface LeverTileState {
  toggleState: Signal;
}

export class LeverTile implements TileDefinition<LeverTileState> {
  graphics = leverTileGraphicsDefinition;
  type = TileType.Lever;

  name = 'Lever';

  createDefaultState: CreateDefaultStateFn<LeverTileState> = (_) => ({
    toggleState: Signal.LOW,
  });

  createActions: CreateActionsFn<LeverTileState> = ({ instance, eventBus }) => ({
    toggle() {
      instance.state.toggleState =
        instance.state.toggleState === Signal.HIGH ? Signal.LOW : Signal.HIGH;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      eventBus!.trigger([instance.globalTilePosition], 'Lever Flipped');
    },
  });

  ioSides = {
    [Side.TOP]: TileSideIOType.OUTPUT,
    [Side.RIGHT]: TileSideIOType.OUTPUT,
    [Side.BOTTOM]: TileSideIOType.OUTPUT,
    [Side.LEFT]: TileSideIOType.OUTPUT,

    [Side.INSIDE]: TileSideIOType.NONE,
  };

  process: ProcessFn<LeverTileState> = ({ instance: { state } }) => {
    if (state.toggleState === Signal.HIGH) {
      return { ...allHighSignal, [Side.INSIDE]: Signal.LOW };
    } else {
      return { ...allLowSignal, [Side.INSIDE]: Signal.LOW };
    }
  };
}

export const leverTileGraphicsDefinition: TileGraphicsDefinition<LeverTileState> = {
  type: TileType.Lever,
  draw: function (g, { state }): void {
    defaultTileGraphics(g);

    g.beginFill(
      state.toggleState === Signal.HIGH ? colors.signalColor : colors.noSignalColor,
    );
    g.lineStyle(3, 0x000000);
    g.drawRect(10, 10, 100, 100);

    g.endFill();

    g.beginFill(0xffffff);
    g.lineStyle(0);

    if (state.toggleState === Signal.HIGH) {
      g.drawRect(8, 72, 104, 40);
    } else {
      g.drawRect(8, 8, 104, 40);
    }
  },
};
