import { TileId } from '../types/ids';
import { ChipInstanceModel } from './ChipInstanceModel';
import { EditorModel } from './EditorModel';
import { globalEventBus } from './EventBus';
import { LogicCircuitModel } from './LogicCircuitModel';
import { TileType } from './TileTypes';
import { InsideChipPortalTileState } from './tiles/InsideChipPortalTile';
import { Side } from './tiles/TileDefinition';
import { TileInstance } from './tiles/TileInstance';

const setAllNodeConnections: (simNode: SimNode | null) => Record<Side, SimNode | null> = (
  node,
) => ({
  [Side.BOTTOM]: node,
  [Side.LEFT]: node,
  [Side.TOP]: node,
  [Side.RIGHT]: node,

  [Side.INSIDE]: node,
});

const defaultNodeConnections: () => Record<Side, SimNode | null> = () =>
  setAllNodeConnections(null);

export class SimulationModel {
  nodeMap: Map<TileId, SimNode> = new Map();

  eventBus = globalEventBus;

  constructor(public editorModel: EditorModel, mainLogicCircuit: LogicCircuitModel) {
    this.connectGraph(null, mainLogicCircuit);
  }

  connectGraph(parentCircuit: LogicCircuitModel | null, logicCircuit: LogicCircuitModel) {
    for (const [key, value] of logicCircuit.logicGraph) {
      this.nodeMap.set(key, value);
    }

    if (!parentCircuit) return;

    for (const [_, chip] of logicCircuit.chipInstances) {
      this.connectGraph(logicCircuit, chip.logicCircuitModel);
      for (const tile of logicCircuit.circuitModel.tileManagerModel.tiles) {
        if (tile.tileType === TileType.InsideChipPortal) {
          const state = tile.state as InsideChipPortalTileState;
          const connectedTile = state.connectedOutsideChipPortalTilePosition;
          if (!connectedTile) continue;

          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const connectedId = logicCircuit.circuitModel.tileManagerModel.get(
            connectedTile.location,
          )!.id;
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this.nodeMap.get(tile.id)!.inputs = setAllNodeConnections(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.nodeMap.get(connectedId)!,
          );
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this.nodeMap.get(tile.id)!.outputs = setAllNodeConnections(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.nodeMap.get(connectedId)!,
          );
        }
      }
    }
  }
}

export class SimNode {
  outputs: Record<Side, SimNode | null> = defaultNodeConnections();
  inputs: Record<Side, SimNode | null> = defaultNodeConnections();

  constructor(public tileInstance: TileInstance) {}
}
