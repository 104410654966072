import { makeAutoObservable } from 'mobx';
import { BiDirectionalMap } from '../utils/Wap/BiDirectionalMap';

export enum KeyActions {
  MOVE_UP = 'Move Up',
  MOVE_DOWN = 'Move Down',
  MOVE_LEFT = 'Move Left',
  MOVE_RIGHT = 'Move Right',
  CENTER = 'CENTER',
  ZOOM_IN = 'Zoom In',
  ZOOM_OUT = 'Zoom Out',
  ZOOM_RESET = 'Zoom Reset',
  PAN_TOOL = 'Pan Tool',
  SELECT_TOOL = 'Select Tool',
  TILE_TOOL = 'Tile Tool',
  CHIP_TOOL = 'Chip Tool',
  DELETE_TOOL = 'Delete Tool',
  DEBUG_TOOL = 'Debug Tool',
}

export const defaultKeyMap = new Map<KeyActions, string>([
  [KeyActions.MOVE_UP, 'ArrowUp'],
  [KeyActions.MOVE_DOWN, 'ArrowDown'],
  [KeyActions.MOVE_LEFT, 'ArrowLeft'],
  [KeyActions.MOVE_RIGHT, 'ArrowRight'],
  [KeyActions.CENTER, 'h'], // h for home
  [KeyActions.ZOOM_IN, '='],
  [KeyActions.ZOOM_OUT, '-'],
  [KeyActions.ZOOM_RESET, '0'],
  [KeyActions.PAN_TOOL, 'p'],
  [KeyActions.SELECT_TOOL, 's'],
  [KeyActions.TILE_TOOL, 't'],
  [KeyActions.CHIP_TOOL, 'c'],
  [KeyActions.DELETE_TOOL, 'x'],
  [KeyActions.DEBUG_TOOL, 'd'],
]);

export class KeyMapModel {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  keyMap = new BiDirectionalMap<KeyActions, string>(defaultKeyMap);

  setChar(key: KeyActions, value: string): void {
    this.keyMap.set(key, value);
  }

  getChar(key: KeyActions): string | undefined {
    return this.keyMap.getValue(key);
  }

  getKeyAction(char: string): KeyActions | undefined {
    return this.keyMap.getKey(char);
  }
}
