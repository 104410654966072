import { makeAutoObservable } from 'mobx';
import { ChipModel } from './ChipModel';
import { ChipId } from '../types/ids';
import { CircuitEditorModel } from './CircuitEditorModel';

export enum ChipEditingMode {
  Logic,
  Structure,
}

export class ChipEditorModel {
  chipId: ChipId;
  editingMode = ChipEditingMode.Logic;

  logicCircuitEditor: CircuitEditorModel;
  structureCircuitEditor: CircuitEditorModel;

  constructor(public chipModel: ChipModel) {
    this.chipId = chipModel.id;

    this.logicCircuitEditor = new CircuitEditorModel(chipModel.logicCircuit.circuitModel);
    this.structureCircuitEditor = new CircuitEditorModel(
      chipModel.structureCircuit.circuitModel,
    );

    makeAutoObservable(this, {}, { autoBind: true });
  }

  get currentCircuitEditor() {
    return {
      [ChipEditingMode.Logic]: this.logicCircuitEditor,
      [ChipEditingMode.Structure]: this.structureCircuitEditor,
    }[this.editingMode];
  }
}
