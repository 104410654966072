import { EditorModel } from '../../models/EditorModel';
import { flex1, fullSize } from '../../styles';
import { FlexColumn, FlexRow } from '../base/Flex';
import { Grid } from './Grid';
import { SideBar } from './SideBar';

export interface EditorProps {
  className?: string;
  editorModel: EditorModel;
}

export function Editor(props: EditorProps) {
  const { className, editorModel } = props;

  return (
    <FlexColumn className={className} css={[fullSize]}>
      {/* <TopBar css={{}} /> */}
      <FlexRow css={[flex1]}>
        <SideBar css={{}} />
        <Grid
          circuitModel={editorModel.currentChipEditor.currentCircuitEditor.circuitModel}
          css={[flex1, {}]}
        />
      </FlexRow>
    </FlexColumn>
  );
}
