import { observer } from 'mobx-react-lite';
import { TileType } from '../../models/TileTypes';
import { Button, ButtonProps, Tooltip } from '@blueprintjs/core';
import { imageMap } from '../../models/tiles/TileRegistry';

export interface TileButtonProps extends ButtonProps {
  selected?: boolean;
  tileType: TileType;
}

export const TileButton = observer((props: TileButtonProps) => {
  const { tileType, selected, ...rest } = props;

  return (
    <Tooltip content={tileType}>
      <Button
        active={selected}
        intent={selected ? 'danger' : 'none'}
        minimal
        css={[
          {
            padding: 2,
            '& > span': {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
          },
        ]}
        {...rest}
      >
        <img
          src={imageMap.get(tileType)}
          alt={tileType}
          style={{ width: 50, height: 50 }}
        />
      </Button>
    </Tooltip>
  );
});
