import { makeAutoObservable } from 'mobx';
import React from 'react';

import { UiModel } from './UiModel';
import { EditorModel } from './EditorModel';
import { PixiManagerModel } from './PixiManagerModel';
import { UserPreferencesModel } from './UserPreferencesModel';
import { ChipStoreModel } from './ChipStoreModel';
import { ProjectModel } from './ProjectModel';
import { ChipModel } from './ChipModel';
import { generateTileImages } from './tiles/TileRegistry';

export class AppModel {
  uiModel = new UiModel(this);
  chipStoreModel = new ChipStoreModel();
  editorModel: EditorModel;
  pixiManagerModel = new PixiManagerModel(this);
  userPreferencesModel = new UserPreferencesModel(this);

  constructor() {
    const chipModel = new ChipModel();
    chipModel.isMain = true;

    this.chipStoreModel.addChip(chipModel);

    this.editorModel = new EditorModel(
      this,
      new ProjectModel(this.chipStoreModel, 'Project Name', chipModel.id),
    );

    this.init();

    makeAutoObservable(this, {}, { autoBind: true });
  }

  async init() {
    await this.pixiManagerModel.load();
    generateTileImages();
  }
}

export const AppModelContext = React.createContext<AppModel | null>(null);

export const useAppModel = (): AppModel => {
  const appModel = React.useContext(AppModelContext);

  if (!appModel) {
    throw new Error('useAppModel must be used within AppModelProvider');
  }

  return appModel;
};
