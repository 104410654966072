import { colors } from '../../styles/theme';
import { Rotation } from '../data/Rotation';
import { Signal } from '../data/Signal';
import { EventBus } from '../EventBus';
import { TileType } from '../TileTypes';
import { Vector2 } from '../Vector2';
import { TileInstance } from './TileInstance';
import * as PIXI from 'pixi.js';

export enum Side {
  TOP = 'top',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  LEFT = 'left',

  /** Used for Lever / Button on Chip Input tiles */
  INSIDE = 'inside',
}

export enum TileSideIOType {
  INPUT = 'input',
  OUTPUT = 'output',

  INPUT_OUTPUT = 'input_output',

  NONE = 'none',
}

export type ProcessFn<TState = null> = (data: {
  instance: TileInstance<TState>;
  eventBus: EventBus;
}) => Record<Side, Signal | null>;

export type CreateDefaultStateFn<TState = null> = (data: {
  meta: TileMeta;
  eventBus?: EventBus;
}) => TState;

export type CreateActionsFn<TState = null> = (data: {
  instance: TileInstance<TState>;
  eventBus?: EventBus;
}) => Record<string, () => void>;

export interface TileDefinition<TState = null> {
  type: TileType;

  name: string;

  createDefaultState: CreateDefaultStateFn<TState>;
  createActions?: CreateActionsFn<TState>;

  ioSides: Record<Side, TileSideIOType>;
  process: ProcessFn<TState>;
  graphics: TileGraphicsDefinition<TState>;
}

export interface TileGraphicsDefinition<TState = null> {
  type: TileType;
  draw: (graphics: PIXI.Graphics, data: { meta: TileMeta; state: TState }) => void;
}

export interface TileMeta {
  connections: Record<Side, boolean>;
  inputs: Record<Side, Signal | null>;
  outputs: Record<Side, Signal | null>;
  rotation: Rotation;
}

export const allLowSignal: Record<Side, Signal | null> = {
  [Side.TOP]: Signal.LOW,
  [Side.RIGHT]: Signal.LOW,
  [Side.BOTTOM]: Signal.LOW,
  [Side.LEFT]: Signal.LOW,

  [Side.INSIDE]: Signal.LOW,
};

export const allHighSignal: Record<Side, Signal | null> = {
  [Side.TOP]: Signal.HIGH,
  [Side.RIGHT]: Signal.HIGH,
  [Side.BOTTOM]: Signal.HIGH,
  [Side.LEFT]: Signal.HIGH,

  [Side.INSIDE]: Signal.HIGH,
};
