import { Container, Graphics } from '@pixi/react';
import { observer } from 'mobx-react-lite';
import { GridViewModel } from './Grid';
import { useRef } from 'react';

import * as PIXI from 'pixi.js';
import { BaseViewModel, useViewModelConstructor } from '../../utils/mobx/ViewModel';
import { makeSimpleAutoObservable } from '../../utils/mobx';

export class UserInteractionLayerViewModel extends BaseViewModel<UserInteractionLayerProps> {
  constructor(props: UserInteractionLayerProps) {
    super(props);
    makeSimpleAutoObservable(this, {}, { autoBind: true });
  }

  get drawFn() {
    const gridViewModel = this.props.gridViewModel;

    const { unscaledTileSize, gridInteractionHandler } = gridViewModel;
    const { mousePositionWorldSpace } = gridInteractionHandler;

    return (g: PIXI.Graphics) => {
      g.clear();

      if (!mousePositionWorldSpace) {
        return;
      }

      g.beginFill(0xffffff, 0.2);
      g.drawRect(
        mousePositionWorldSpace.x * unscaledTileSize,
        mousePositionWorldSpace.y * unscaledTileSize,
        unscaledTileSize,
        unscaledTileSize,
      );
      g.endFill();
    };
  }
}

export interface UserInteractionLayerProps {
  gridViewModel: GridViewModel;
}

export const UserInteractionLayer = observer((props: UserInteractionLayerProps) => {
  const vm = useViewModelConstructor(UserInteractionLayerViewModel, props);

  const graphicsRef = useRef<PIXI.Graphics | null>(null);

  return (
    <Container>
      <Graphics ref={graphicsRef} draw={vm.drawFn} />
    </Container>
  );
});
