/**
 * Clamps a number between a minimum and maximum value.
 * @param value
 * @param min minimum value
 * @param max maximum value
 * @returns
 */
export function clamp(value: number, min: number, max: number): number {
  return Math.min(Math.max(value, min), max);
}

/**
 * Maps a value from a given domain to a specified range.
 *
 * @param {number} domainStart - The start of the domain (input range).
 * @param {number} domainEnd - The end of the domain (input range).
 * @param {number} rangeStart - The start of the range (output range).
 * @param {number} rangeEnd - The end of the range (output range).
 * @param {number} value - The value in the domain to map to the range.
 * @returns {number} The mapped value in the range.
 *
 * @example
 * // Map 5 from domain [0, 10] to range [100, 200]
 * const result = lerp(0, 10, 100, 200, 5);
 * console.log(result); // 150
 *
 * @example
 * // Extrapolate a value outside the domain
 * const result = lerp(0, 10, 100, 200, 15);
 * console.log(result); // 250
 */
export function lerp(
  domainStart: number,
  domainEnd: number,
  rangeStart: number,
  rangeEnd: number,
  value: number,
): number {
  const normalizedValue = (value - domainStart) / (domainEnd - domainStart);
  return rangeStart + normalizedValue * (rangeEnd - rangeStart);
}
