import { colors } from '../../styles';
import { Signal } from '../data/Signal';
import { Vector2 } from '../Vector2';
import { Side, TileSideIOType, TileMeta } from './TileDefinition';
import * as PIXI from 'pixi.js';

export const defaultTileGraphics = (graphics: PIXI.Graphics) => {
  graphics.beginFill(colors.tileBackground);
  graphics.drawRect(0, 0, 120, 120);
  graphics.endFill();
};

export const sideGraphics = (
  graphics: PIXI.Graphics,
  ioSides: Record<Side, TileSideIOType>,
  highColor: PIXI.ColorSource,
  lowColor: PIXI.ColorSource,
  meta: TileMeta,
) => {
  // 120x120

  const center = new Vector2(60, 60);

  const arrowLength = 10; // Size of the arrow
  const arrowWidth = 10; // Size of the arrow
  const edgeOffset = 40; // Distance from the center to the edge (adjust as needed)

  const drawArrow = (
    graphics: PIXI.Graphics,
    angleDeg: number,
    direction: 'in' | 'out' | 'both',
    color: PIXI.ColorSource,
  ) => {
    const angleRad = (angleDeg * Math.PI) / 180;

    const rotateAroundCenter = (vec: Vector2, radians: number) =>
      vec.rotateAround(center, radians);

    graphics.beginFill(color);

    const centeredRightPointingTrianglePoints = [
      new Vector2(0, 0),
      new Vector2(-arrowLength, arrowWidth / 2),
      new Vector2(-arrowLength, -arrowWidth / 2),
    ].map((point) => point.add(new Vector2(arrowLength / 2, 0)));

    if (direction === 'out' || direction === 'both') {
      const points = centeredRightPointingTrianglePoints;

      const shiftedPoints = points.map((point) =>
        point.add(new Vector2(center.x + edgeOffset + arrowLength / 2, center.y)),
      );

      const rotatedPoints = shiftedPoints.map((point) =>
        rotateAroundCenter(point, angleRad),
      );

      graphics.drawPolygon(rotatedPoints.flatMap((point) => point.asTuple));
    }

    if (direction === 'in' || direction === 'both') {
      const points = centeredRightPointingTrianglePoints.map((point) =>
        point.rotate(Math.PI),
      );

      const shiftedPoints = points.map((point) =>
        point.add(new Vector2(center.x + edgeOffset + arrowLength / 2, center.y)),
      );

      const rotatedPoints = shiftedPoints.map((point) =>
        rotateAroundCenter(point, angleRad),
      );

      graphics.drawPolygon(rotatedPoints.flatMap((point) => point.asTuple));
    }

    graphics.endFill();
  };

  // Top
  if (ioSides[Side.TOP] === TileSideIOType.INPUT) {
    const color = meta.inputs.top === Signal.HIGH ? highColor : lowColor;

    drawArrow(graphics, 270, 'in', color);
  } else if (ioSides[Side.TOP] === TileSideIOType.OUTPUT) {
    const color = meta.outputs.top === Signal.HIGH ? highColor : lowColor;

    drawArrow(graphics, 270, 'out', color);
  } else if (ioSides[Side.TOP] === TileSideIOType.INPUT_OUTPUT) {
    const color = [meta.inputs.top, meta.inputs.bottom].includes(Signal.HIGH)
      ? highColor
      : lowColor;

    drawArrow(graphics, 270, 'both', color);
  }

  // Right
  if (ioSides[Side.RIGHT] === TileSideIOType.INPUT) {
    const color = meta.inputs.right === Signal.HIGH ? highColor : lowColor;

    drawArrow(graphics, 0, 'in', color);
  } else if (ioSides[Side.RIGHT] === TileSideIOType.OUTPUT) {
    const color = meta.outputs.right === Signal.HIGH ? highColor : lowColor;

    drawArrow(graphics, 0, 'out', color);
  } else if (ioSides[Side.RIGHT] === TileSideIOType.INPUT_OUTPUT) {
    const color = [meta.inputs.right, meta.outputs.right].includes(Signal.HIGH)
      ? highColor
      : lowColor;

    drawArrow(graphics, 0, 'both', color);
  }

  // Bottom
  if (ioSides[Side.BOTTOM] === TileSideIOType.INPUT) {
    const color = meta.inputs.bottom === Signal.HIGH ? highColor : lowColor;

    drawArrow(graphics, 90, 'in', color);
  } else if (ioSides[Side.BOTTOM] === TileSideIOType.OUTPUT) {
    const color = meta.outputs.bottom === Signal.HIGH ? highColor : lowColor;

    drawArrow(graphics, 90, 'out', color);
  } else if (ioSides[Side.BOTTOM] === TileSideIOType.INPUT_OUTPUT) {
    const color = [meta.inputs.bottom, meta.outputs.bottom].includes(Signal.HIGH)
      ? highColor
      : lowColor;

    drawArrow(graphics, 90, 'both', color);
  }

  // Left
  if (ioSides[Side.LEFT] === TileSideIOType.INPUT) {
    const color = meta.inputs.left === Signal.HIGH ? highColor : lowColor;

    drawArrow(graphics, 180, 'in', color);
  } else if (ioSides[Side.LEFT] === TileSideIOType.OUTPUT) {
    const color = meta.outputs.left === Signal.HIGH ? highColor : lowColor;

    drawArrow(graphics, 180, 'out', color);
  } else if (ioSides[Side.LEFT] === TileSideIOType.INPUT_OUTPUT) {
    const color = [meta.inputs.left, meta.outputs.left].includes(Signal.HIGH)
      ? highColor
      : lowColor;

    drawArrow(graphics, 180, 'both', color);
  }
};

export const connectionGraphics = (
  graphics: PIXI.Graphics,
  highColor: PIXI.ColorSource,
  lowColor: PIXI.ColorSource,
  meta: TileMeta,
) => {
  const center = new Vector2(60, 60);
  const width = 2;
  const height = 30;

  const drawConnection = (color: PIXI.ColorSource, angleDeg: number) => {
    const angleRad = (angleDeg * Math.PI) / 180;

    const rotateAroundCenter = (vec: Vector2, radians: number) =>
      vec.rotateAround(center, radians);

    graphics.beginFill(color);

    const rectPoints = [
      new Vector2(0, 0),
      new Vector2(width, 0),
      new Vector2(width, height),
      new Vector2(0, height),
    ].map((point) => point.sub(new Vector2(width / 2, height / 2)));

    const shiftedPoints = rectPoints.map((point) =>
      point.add(new Vector2(120 - width / 2, center.y)),
    );

    const rotatedPoints = shiftedPoints.map((point) =>
      rotateAroundCenter(point, angleRad),
    );

    graphics.drawPolygon(rotatedPoints.flatMap((point) => point.asTuple));

    graphics.endFill();
  };

  // Top
  if (meta.connections.top) {
    const color = [meta.inputs.top, meta.outputs.bottom].includes(Signal.HIGH)
      ? highColor
      : lowColor;

    drawConnection(color, 270);
  }

  // Right
  if (meta.connections.right) {
    const color = [meta.inputs.right, meta.outputs.left].includes(Signal.HIGH)
      ? highColor
      : lowColor;

    drawConnection(color, 0);
  }

  // Bottom
  if (meta.connections.bottom) {
    const color = [meta.inputs.bottom, meta.outputs.top].includes(Signal.HIGH)
      ? highColor
      : lowColor;

    drawConnection(color, 90);
  }

  // Left
  if (meta.connections.left) {
    const color = [meta.inputs.left, meta.outputs.right].includes(Signal.HIGH)
      ? highColor
      : lowColor;

    drawConnection(color, 180);
  }
};
