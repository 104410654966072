import { colors } from '../../styles';
import { Signal } from '../data/Signal';
import { TileType } from '../TileTypes';
import { defaultTileGraphics } from './DefaultTileGraphics';
import {
  TileDefinition,
  Side,
  TileSideIOType,
  allLowSignal,
  ProcessFn,
  TileGraphicsDefinition,
} from './TileDefinition';

export class InverterTile implements TileDefinition {
  graphics = notTileGraphicsDefinition;
  type = TileType.Not;

  name = 'Inverter';

  createDefaultState = () => null;

  ioSides = {
    [Side.TOP]: TileSideIOType.NONE,
    [Side.RIGHT]: TileSideIOType.OUTPUT,
    [Side.BOTTOM]: TileSideIOType.NONE,
    [Side.LEFT]: TileSideIOType.INPUT,

    [Side.INSIDE]: TileSideIOType.NONE,
  };

  process: ProcessFn = ({ instance: { meta } }) => {
    // Flip the signal: HIGH becomes LOW, LOW becomes HIGH
    return {
      ...allLowSignal,
      right: meta.inputs.left === Signal.HIGH ? Signal.LOW : Signal.HIGH,
    };
  };
}

export const notTileGraphicsDefinition: TileGraphicsDefinition = {
  type: TileType.Not,
  draw: function (g, { meta }): void {
    defaultTileGraphics(g);

    g.beginFill(
      meta.outputs.right === Signal.HIGH ? colors.signalColor : colors.noSignalColor,
    );

    g.drawPolygon([10, 10, 95, 60, 10, 110]);

    g.drawCircle(100, 60, 15);

    g.endFill();
  },
};
