import { Editor } from '../components/editor/Editor';
import { absolute, flexCenter, fullSize } from '../styles';
import { useAppModel } from '../models/AppModel';
import { Classes } from '@blueprintjs/core';

export function EditorPage() {
  const appModel = useAppModel();

  return (
    <div css={[absolute(), fullSize, flexCenter]} className={Classes.DARK}>
      <Editor editorModel={appModel.editorModel} />
    </div>
  );
}
