import { useEffect, useRef } from 'react';

export function useResizeObserver<T extends HTMLElement>(
  cb: (entry: ResizeObserverEntry) => void,
) {
  const elementRef = useRef<T | null>(null);

  useEffect(() => {
    if (!elementRef.current) return;

    const observer = new ResizeObserver(([entry]) => cb(entry));

    observer.observe(elementRef.current);

    return () => {
      observer.disconnect();
    };
  }, [elementRef.current, cb]);

  return elementRef;
}
