import { GlobalTilePosition } from '../../types';
import { Signal } from '../data/Signal';
import { TileType } from '../TileTypes';
import { defaultTileGraphics } from './DefaultTileGraphics';
import {
  allHighSignal,
  allLowSignal,
  CreateActionsFn,
  CreateDefaultStateFn,
  ProcessFn,
  Side,
  TileDefinition,
  TileGraphicsDefinition,
  TileSideIOType,
} from './TileDefinition';

export interface InsideChipPortalTileState {
  label: string | null;
  hue: number;

  connectedOutsideChipPortalTilePosition: GlobalTilePosition | null;
}

export class InsideChipPortalTile implements TileDefinition<InsideChipPortalTileState> {
  createActions?: CreateActionsFn<InsideChipPortalTileState> | undefined;
  graphics = insideChipPortalTileGraphicsDefinition;
  type = TileType.InsideChipPortal;

  name = 'Portal Tile (Inside)';

  createDefaultState: CreateDefaultStateFn<InsideChipPortalTileState> = () => ({
    label: null,
    hue: 0,
    connectedOutsideChipPortalTilePosition: null,
  });

  ioSides = {
    [Side.TOP]: TileSideIOType.INPUT_OUTPUT,
    [Side.RIGHT]: TileSideIOType.INPUT_OUTPUT,
    [Side.BOTTOM]: TileSideIOType.INPUT_OUTPUT,
    [Side.LEFT]: TileSideIOType.INPUT_OUTPUT,

    [Side.INSIDE]: TileSideIOType.INPUT_OUTPUT,
  };

  process: ProcessFn<InsideChipPortalTileState> = ({ instance: { meta } }) => {
    const inputValues = Object.values(meta.inputs);

    const isHigh = inputValues.some((signal) => signal === Signal.HIGH);

    if (isHigh) {
      return {
        ...allHighSignal,
      };
    } else {
      return {
        ...allLowSignal,
      };
    }
  };
}

export const insideChipPortalTileGraphicsDefinition: TileGraphicsDefinition<InsideChipPortalTileState> =
  {
    type: TileType.InsideChipPortal,
    draw: function (g, { state, meta }): void {
      defaultTileGraphics(g);

      const isHigh = Object.values(meta.outputs).some((sig) => sig === Signal.HIGH);

      g.beginFill({
        h: state.hue,
        s: 100,
        v: isHigh ? 100 : 35,
      });
      g.drawRect(5, 5, 110, 110);
      g.endFill();
    },
  };
