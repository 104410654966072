import { autorun, makeAutoObservable, reaction } from 'mobx';
import { EditorModel } from '../EditorModel';
import { TileType } from '../TileTypes';

export class TileSelectModel {
  selectedTileType: TileType | null = null;

  constructor(private editorModel: EditorModel) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.setupReactions();
  }

  selectTileType(tileType: TileType) {
    this.selectedTileType = tileType;
  }

  private setupReactions() {
    autorun(() => {
      if (this.selectedTileType === null) {
        return;
      }

      if (!this.selectableTileTypes.includes(this.selectedTileType)) {
        this.selectedTileType = null;
      }
    });
  }

  get selectableTileTypes() {
    return this.editorModel.currentChipEditor.currentCircuitEditor.placeableTiles ?? [];
  }
}
