import { Point } from '../models/Point';

export enum Direction {
  UP,
  DOWN,
  LEFT,
  RIGHT,
}

export const directions = [
  Direction.UP,
  Direction.DOWN,
  Direction.LEFT,
  Direction.RIGHT,
] as const;

export function opposite(direction: Direction): Direction {
  switch (direction) {
    case Direction.UP:
      return Direction.DOWN;
    case Direction.DOWN:
      return Direction.UP;
    case Direction.LEFT:
      return Direction.RIGHT;
    case Direction.RIGHT:
      return Direction.LEFT;
  }
}

export function isOpposite(direction1: Direction, direction2: Direction): boolean {
  return opposite(direction1) === direction2;
}

export function isVertical(direction: Direction): boolean {
  return direction === Direction.UP || direction === Direction.DOWN;
}

export function isHorizontal(direction: Direction): boolean {
  return direction === Direction.LEFT || direction === Direction.RIGHT;
}

export function directionRotateCW(direction: Direction): Direction {
  switch (direction) {
    case Direction.UP:
      return Direction.RIGHT;
    case Direction.RIGHT:
      return Direction.DOWN;
    case Direction.DOWN:
      return Direction.LEFT;
    case Direction.LEFT:
      return Direction.UP;
  }
}

export function directionRotateCCW(direction: Direction): Direction {
  switch (direction) {
    case Direction.UP:
      return Direction.LEFT;
    case Direction.LEFT:
      return Direction.DOWN;
    case Direction.DOWN:
      return Direction.RIGHT;
    case Direction.RIGHT:
      return Direction.UP;
  }
}

export function directionRotate180(direction: Direction): Direction {
  switch (direction) {
    case Direction.UP:
      return Direction.DOWN;
    case Direction.DOWN:
      return Direction.UP;
    case Direction.LEFT:
      return Direction.RIGHT;
    case Direction.RIGHT:
      return Direction.LEFT;
  }
}

export function directionToPoint(direction: Direction): Point {
  switch (direction) {
    case Direction.UP:
      return new Point(0, -1);
    case Direction.DOWN:
      return new Point(0, 1);
    case Direction.LEFT:
      return new Point(-1, 0);
    case Direction.RIGHT:
      return new Point(1, 0);
  }
}
