import { absolute, border, colors, fullSize, relative } from '../../styles';
import { useEffect } from 'react';
import { useResizeObserver } from '../../utils/hooks';
import { BaseViewModel, useViewModelConstructor } from '../../utils/mobx/ViewModel';
import { makeSimpleAutoObservable } from '../../utils/mobx';
import { observer } from 'mobx-react-lite';
import { action } from 'mobx';
import { Point } from '../../models/Point';
import { Bounds, Bounds2D, clampToBounds } from '../../utils/bounds';
import { Stage } from '../pixi/Stage';
import { CircuitModel } from '../../models/CircuitModel';
import { TileLayer } from './TileLayer';
import { GridLineLayer } from './GridLineLayer';
import { AppModel, useAppModel } from '../../models/AppModel';
import { GridInteractionHandler } from '../../models/grid/GridInteractionHandler';

export interface GridViewModelProps {
  parentElementRef: React.RefObject<HTMLDivElement>;
  appModel: AppModel;
  circuitModel: CircuitModel;
}

export class GridViewModel extends BaseViewModel<GridViewModelProps> {
  gridInteractionHandler = new GridInteractionHandler(this);

  readonly unscaledTileSize: number = 50;
  readonly zoomBounds: Bounds = { min: 0.2, max: 4 };

  width = 0;
  height = 0;

  readonly resolution: number = 2;

  zoom = 1;
  offset: Point = new Point(0, 0);

  constructor(props: GridViewModelProps) {
    super(props);
    makeSimpleAutoObservable(this, {}, { autoBind: true });
  }

  postSetProps(): void {
    this.gridInteractionHandler.setupListeners();
  }

  screenSpaceToWorldSpace(screenSpacePoint: Point, snap: boolean): Point {
    let x = screenSpacePoint.x;
    let y = screenSpacePoint.y;

    x -= this.offset.x;
    y -= this.offset.y;

    x /= this.tileSize;
    y /= this.tileSize;

    if (snap) {
      x = Math.floor(x);
      y = Math.floor(y);
    }

    return new Point(x, y);
  }

  worldSpaceToScreenSpace(worldSpacePoint: Point, snap: boolean): Point {
    let x = worldSpacePoint.x;
    let y = worldSpacePoint.y;

    x *= this.tileSize;
    y *= this.tileSize;

    x += this.offset.x;
    y += this.offset.y;

    if (snap) {
      x = Math.floor(x);
      y = Math.floor(y);
    }

    return new Point(x, y);
  }

  centerToWorldSpacePoint(point: Point) {
    const centerScreenSpace = this.worldSpaceToScreenSpace(point, false);

    const newOffset = this.center.sub(centerScreenSpace);

    this.offset = newOffset;
  }

  handleZoom(centerPos: Point, zoomChange: number) {
    const centerPosInWorldBeforeZoom = this.screenSpaceToWorldSpace(centerPos, false);

    this.zoom += zoomChange * this.zoom;
    this.zoom = clampToBounds(this.zoom, this.zoomBounds);

    const centerPosInWorldAfterZoom = this.screenSpaceToWorldSpace(centerPos, false);

    const centerPosInScreenBeforeZoom = this.worldSpaceToScreenSpace(
      centerPosInWorldBeforeZoom,
      false,
    );
    const centerPosInScreenAfterZoom = this.worldSpaceToScreenSpace(
      centerPosInWorldAfterZoom,
      false,
    );

    const centerPosChangeInScreen = centerPosInScreenAfterZoom.sub(
      centerPosInScreenBeforeZoom,
    );
    this.offset = this.offset.add(centerPosChangeInScreen);
  }

  get viewableScreenBounds(): Bounds2D {
    return {
      top: 0,
      left: 0,
      right: this.width,
      bottom: this.height,
    };
  }

  get viewableWorldBounds(): Bounds2D {
    const topLeft = this.screenSpaceToWorldSpace(
      new Point(this.viewableScreenBounds.left, this.viewableScreenBounds.top),
      true,
    );
    const bottomRight = this.screenSpaceToWorldSpace(
      new Point(this.viewableScreenBounds.right, this.viewableScreenBounds.bottom),
      true,
    );

    return {
      top: topLeft.y,
      left: topLeft.x,
      right: bottomRight.x,
      bottom: bottomRight.y,
    };
  }

  get center(): Point {
    return new Point(this.width / 2, this.height / 2);
  }

  get centerInWorldSpace(): Point {
    return this.screenSpaceToWorldSpace(this.center, true);
  }

  get tileSize() {
    return this.unscaledTileSize * this.zoom;
  }

  get majorGridLineCount() {
    if (this.zoom > 1) {
      return 4;
    }

    if (this.zoom > 0.5) {
      return 8;
    }

    if (this.zoom > 0.25) {
      return 16;
    }

    return 32;
  }

  get gridLineThicknessMultiplier() {
    return 1; // keep the lines the same thickness
    // return Math.sqrt(this.zoom); // on zoom out the lines get thinner but not as much
    // return this.zoom; // on zoom out the lines get thinner
  }
}

export interface GridProps {
  className?: string;
  circuitModel: CircuitModel;
}

export const Grid = observer((props: GridProps) => {
  const { className, circuitModel } = props;

  const appModel = useAppModel();

  const parentElementRef = useResizeObserver<HTMLDivElement>(
    action((entry) => {
      const { width, height } = entry.contentRect;

      gridViewModel.width = width;
      gridViewModel.height = height;
    }),
  );

  const gridViewModel = useViewModelConstructor(GridViewModel, {
    parentElementRef,
    appModel,
    circuitModel,
  });

  useEffect(
    () => gridViewModel.gridInteractionHandler.destructor,
    [parentElementRef, circuitModel],
  );

  return (
    <div
      className={className}
      css={[
        fullSize,
        border('black', 1),
        {
          borderLeft: 'none',
          borderTop: 'none',
          backgroundColor: colors.grid,
          overflow: 'hidden',
        },
        relative(),
      ]}
    >
      <div ref={parentElementRef} css={[absolute(0, 0, 0, 0)]}>
        <PixiGrid gridViewModel={gridViewModel} />
      </div>
    </div>
  );
});

export interface PixiGridProps {
  gridViewModel: GridViewModel;
}

export const PixiGrid = observer((props: PixiGridProps) => {
  const { gridViewModel } = props;

  return (
    <Stage
      width={gridViewModel.width}
      height={gridViewModel.height}
      options={{
        backgroundAlpha: 0,
        resolution: gridViewModel.resolution,
        antialias: false,
      }}
    >
      <GridLineLayer gridViewModel={gridViewModel} />

      <TileLayer gridViewModel={gridViewModel} />
    </Stage>
  );
});
