export const standardSizes = {
  xs: 2,
  sm: 5,
  md: 10,
  lg: 15,
  xl: 25,
} as const;

export const fontStyles = {
  primary: 'Arial',
  secondary: 'Arial',
};

/**
 * Font sizes used in the theme.
 */
export const fontSizes = {
  xs: '.4em',
  sm: '.8em',
  smedium: '1.2em',
  md: '1.6em',
  lg: '2.5em',
  xl: '4.8em',
} as const;

/**
 * Defines the color palette used in the application.
 */
export const colors = {
  primary: '#d65259',
  secondary: '#8f8f8f',
  accent: '#ff6c6c',
  grid: '#333333',
  background: '#EEE',
  border: '#f14949',
  borderFocused: '#f34e4e',
  text: '#000',
  textSecondary: '#000',
  component: '#a0a0a0',
  componentSecondary: '#313131',
  transparent: 'rgba(0,0,0,0.22)',
  tileBackground: '#3a3a3a',
  signalColor: '#ff0000',
  noSignalColor: '#590000',
  wireBorder: '#696969',
} as const;

/**
 * Object containing gradient values for different purposes.
 */
export const gradients = {
  // default: 'linear-gradient(#888888, #4DACFF)',
};

/**
 * The standard shadows used in the theme.
 */
export const standardShadows = {
  0: '0 0 0 rgba(0,0,0,0.12), 0 0 0 rgba(0,0,0,0.24)',
  1: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  2: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
  3: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
  4: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
  5: '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)',
} as const;

/**
 * Transition times for animations.
 */
export const transitionTimes = {
  fast: '0.2s',
  medium: '0.4s',
  slow: '0.6s',
  debug: '5s',
} as const;
