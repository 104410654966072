export type EventCreator = <K extends keyof HTMLElementEventMap>(
  eventName: K,
  listener: (event: HTMLElementEventMap[K]) => void,
) => () => void;

export function eventListenerBuilder(element: HTMLElement): {
  eventCreator: EventCreator;
  destroy: () => void;
} {
  const destroyers: (() => void)[] = [];

  return {
    eventCreator: <K extends keyof HTMLElementEventMap>(
      eventName: K,
      listener: (event: HTMLElementEventMap[K]) => void,
    ) => {
      element.addEventListener(eventName, listener);

      const destroyer = () => {
        element.removeEventListener(eventName, listener);
      };

      destroyers.push(destroyer);

      return destroyer;
    },
    destroy: () => {
      destroyers.forEach((destroyer) => destroyer());
    },
  };
}
