import { IconName } from '@blueprintjs/icons';
import { EditingMode, EditorModel } from '../../models/EditorModel';
import { Button, Tooltip } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

export interface ModeButtonInfo {
  name: string;
  mode: EditingMode;
  icon: IconName;
}

export interface ModeButtonProps extends ModeButtonInfo {
  editorModel: EditorModel;
}

export const ModeButton = observer((props: ModeButtonProps) => {
  const { name: content, mode, icon, editorModel } = props;

  return (
    <Tooltip content={content}>
      <Button
        active={editorModel.editingMode === mode}
        onClick={() => editorModel.setEditingMode(mode)}
        large
        minimal
        icon={icon}
      />
    </Tooltip>
  );
});
