import { action, makeAutoObservable } from 'mobx';
import { GridViewModel } from '../../components/editor/Grid';
import { Point } from '../Point';
import { clampToBounds } from '../../utils/bounds';
import { eventListenerBuilder } from '../../utils/events';
import { EditingMode } from '../EditorModel';
import { KeyActions } from '../KeyMapModel';
import { ActionType } from '../HistoryModel';
import { TileInstance } from '../tiles/TileInstance';
import { globalEventBus } from '../EventBus';

export class GridInteractionHandler {
  destroyers: (() => void)[] = [];
  mousePositionWorldSpace: Point | null = null;

  constructor(private gridVm: GridViewModel) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  handleZoom(centerPos: Point, zoomChange: number) {
    const centerPosInWorldBeforeZoom = this.gridVm.screenSpaceToWorldSpace(
      centerPos,
      false,
    );

    this.gridVm.zoom += zoomChange * this.gridVm.zoom;
    this.gridVm.zoom = clampToBounds(this.gridVm.zoom, this.gridVm.zoomBounds);

    const centerPosInWorldAfterZoom = this.gridVm.screenSpaceToWorldSpace(
      centerPos,
      false,
    );

    const centerPosInScreenBeforeZoom = this.gridVm.worldSpaceToScreenSpace(
      centerPosInWorldBeforeZoom,
      false,
    );
    const centerPosInScreenAfterZoom = this.gridVm.worldSpaceToScreenSpace(
      centerPosInWorldAfterZoom,
      false,
    );

    const centerPosChangeInScreen = centerPosInScreenAfterZoom.sub(
      centerPosInScreenBeforeZoom,
    );
    this.gridVm.offset = this.gridVm.offset.add(centerPosChangeInScreen);
  }

  setupListeners() {
    if (this.destroyers && this.destroyers.length > 0) {
      this.destructor();
    }

    const { parentElementRef } = this.gridVm.props;

    if (!parentElementRef.current) {
      return;
    }

    const parentElement = parentElementRef.current;

    const { eventCreator: bodyEventCreator, destroy: bodyDestroy } = eventListenerBuilder(
      document.body,
    );

    this.destroyers.push(bodyDestroy);

    bodyEventCreator(
      'keydown',
      action((event) => {
        const { keyMapModel } = this.gridVm.props.appModel.userPreferencesModel;

        const key = event.key;
        const keyAction = keyMapModel.getKeyAction(key);

        if (!keyAction) return;

        switch (keyAction) {
          case KeyActions.MOVE_LEFT:
            this.gridVm.offset = this.gridVm.offset.add(
              new Point(this.gridVm.tileSize, 0),
            );
            break;
          case KeyActions.MOVE_RIGHT:
            this.gridVm.offset = this.gridVm.offset.add(
              new Point(-this.gridVm.tileSize, 0),
            );
            break;
          case KeyActions.MOVE_UP:
            this.gridVm.offset = this.gridVm.offset.add(
              new Point(0, this.gridVm.tileSize),
            );
            break;
          case KeyActions.MOVE_DOWN:
            this.gridVm.offset = this.gridVm.offset.add(
              new Point(0, -this.gridVm.tileSize),
            );
            break;
          case KeyActions.CENTER:
            this.gridVm.offset = new Point(0, 0);
            break;
          case KeyActions.ZOOM_IN:
            this.handleZoom(this.gridVm.center, 0.1);
            break;
          case KeyActions.ZOOM_OUT:
            this.handleZoom(this.gridVm.center, -0.1);
            break;
          case KeyActions.ZOOM_RESET:
            this.handleZoom(this.gridVm.center, 1 / this.gridVm.zoom - 1);
            break;
        }
      }),
    );

    const { eventCreator, destroy } = eventListenerBuilder(parentElement);

    this.destroyers.push(destroy);

    eventCreator(
      'wheel',
      action((event) => {
        event.preventDefault();

        const { clientX, clientY, deltaY } = event;
        const { left, top } = parentElement.getBoundingClientRect();
        const mousePosition = new Point(clientX - left, clientY - top);

        const zoomMultiplier = 0.001;
        const zoomChange = -deltaY * zoomMultiplier;

        this.handleZoom(mousePosition, zoomChange);

        const currentMousePositionWorldSpace = this.gridVm.screenSpaceToWorldSpace(
          mousePosition,
          true,
        );

        // ! Used for debugging
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).currentMousePositionWorldSpace =
          currentMousePositionWorldSpace.asTuple;

        if (
          !this.mousePositionWorldSpace ||
          !this.mousePositionWorldSpace.equals(currentMousePositionWorldSpace)
        ) {
          this.mousePositionWorldSpace = currentMousePositionWorldSpace;
        }
      }),
    );

    let isDragging = false;
    let lastMousePosition: Point | null = null;

    eventCreator(
      'mousedown',
      action((event) => {
        isDragging = true;

        const { clientX, clientY } = event;

        const { left, top } = parentElement.getBoundingClientRect();

        lastMousePosition = new Point(clientX - left, clientY - top);

        // TODO: Move this to a more appropriate place
        const editorModel = this.gridVm.props.appModel.editorModel;
        if (editorModel.editingMode === EditingMode.PLACE_TILE) {
          const selectedTileType = editorModel.tileSelectModel.selectedTileType;

          if (!selectedTileType) {
            return;
          }
          const worldSpaceMousePosition = this.gridVm.screenSpaceToWorldSpace(
            lastMousePosition,
            true,
          );

          const circuitEditorModel = editorModel.currentChipEditor.currentCircuitEditor;

          circuitEditorModel.runAction({
            type: ActionType.AddTiles,
            description: 'Place Tile',
            do: () => {
              circuitEditorModel.circuitModel.tileManagerModel.add(
                new TileInstance(
                  selectedTileType,
                  {
                    // TODO: Figure out where to get the chip instance id from
                    chipInstanceId: editorModel.project.mainChipId,
                    location: worldSpaceMousePosition,
                  },
                  globalEventBus,
                  circuitEditorModel.circuitModel.tileManagerModel,
                ),
              );
            },
            undo: () => {
              // TODO: Implement undo
              // circuitEditorModel.circuitModel.tileManagerModel.removeTile(
              //   worldSpaceMousePosition,
              // );
            },
          });

          event.preventDefault();
        }
      }),
    );

    eventCreator(
      'mouseup',
      action(() => {
        isDragging = false;
        lastMousePosition = null;
      }),
    );

    eventCreator(
      'mousemove',
      action((event) => {
        const { clientX, clientY } = event;

        const { left, top } = parentElement.getBoundingClientRect();

        const mousePosition = new Point(clientX - left, clientY - top);
        const currentMousePositionWorldSpace = this.gridVm.screenSpaceToWorldSpace(
          mousePosition,
          true,
        );

        // ! Used for debugging
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).currentMousePositionWorldSpace =
          currentMousePositionWorldSpace.asTuple;

        if (
          !this.mousePositionWorldSpace ||
          !this.mousePositionWorldSpace.equals(currentMousePositionWorldSpace)
        ) {
          this.mousePositionWorldSpace = currentMousePositionWorldSpace;
        }

        if (!isDragging) {
          return;
        }

        if (!lastMousePosition) {
          lastMousePosition = mousePosition;
          return;
        }

        if (this.isPanning) {
          const movementDelta = mousePosition.sub(lastMousePosition);

          this.gridVm.offset = this.gridVm.offset.add(movementDelta);
        }

        lastMousePosition = mousePosition;
      }),
    );
  }

  destructor(): void {
    this.destroyers.forEach((destroyer) => destroyer());
  }

  get isPanning(): boolean {
    return this.gridVm.props.appModel.editorModel.editingMode === EditingMode.PAN;
  }
}
