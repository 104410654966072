import { colors } from '../../styles';
import { Signal } from '../data/Signal';
import { TileType } from '../TileTypes';
import {
  TileDefinition,
  Side,
  TileSideIOType,
  ProcessFn,
  allLowSignal,
  TileGraphicsDefinition,
} from './TileDefinition';
import * as PIXI from 'pixi.js';

import { appModel } from '../../App';
import { TextureKey } from '../PixiManagerModel';
import {
  defaultTileGraphics,
  sideGraphics,
  connectionGraphics,
} from './DefaultTileGraphics';

export const randomIoSides = {
  [Side.TOP]: TileSideIOType.NONE,
  [Side.RIGHT]: TileSideIOType.OUTPUT,
  [Side.BOTTOM]: TileSideIOType.NONE,
  [Side.LEFT]: TileSideIOType.INPUT,

  [Side.INSIDE]: TileSideIOType.NONE,
};

export interface RandomTileState {
  prevInput: Signal | null;
  lastOutput: Signal;
}

export class RandomTile implements TileDefinition<RandomTileState> {
  graphics = randomTileGraphicsDefinition;
  type = TileType.Random;

  name = 'Random';

  createDefaultState = () => ({
    prevInput: null,
    lastOutput: Math.random() > 0.5 ? Signal.HIGH : Signal.LOW,
  });

  ioSides = randomIoSides;

  process: ProcessFn<RandomTileState> = ({ instance: { meta, state } }) => {
    if (state.prevInput === Signal.LOW && meta.inputs.left === Signal.HIGH) {
      state.lastOutput = Math.random() > 0.5 ? Signal.HIGH : Signal.LOW;
    }

    state.prevInput = meta.inputs.left;

    return { ...allLowSignal, right: state.lastOutput };
  };
}

export const randomTileGraphicsDefinition: TileGraphicsDefinition<RandomTileState> = {
  type: TileType.Random,
  draw: function (g, { meta }): void {
    defaultTileGraphics(g);
    sideGraphics(g, randomIoSides, colors.signalColor, colors.noSignalColor, meta);
    connectionGraphics(g, colors.signalColor, colors.noSignalColor, meta);

    const color =
      meta.outputs.right === Signal.HIGH ? colors.signalColor : colors.noSignalColor;

    const sprite = new PIXI.Sprite(appModel.pixiManagerModel.textures[TextureKey.Random]);
    sprite.width = 70;
    sprite.height = 70;

    sprite.x = 25;
    sprite.y = 25;

    sprite.tint = color;

    g.addChild(sprite);
  },
};
